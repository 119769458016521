import clsx from 'clsx';
import React from 'react';
import { Footer as FooterModel, SocialLinkTypes } from 'shared/api';
import { Button, Icons, Typography } from 'shared/ui';

import styles from './styles.module.scss';

const getSocialIcon = (type: SocialLinkTypes) => {
	if (type === SocialLinkTypes.VK) {
		return <Icons.VK />;
	}

	if (type === SocialLinkTypes.Telegram) {
		return <Icons.Telegram />;
	}

	if (type === SocialLinkTypes.Youtube) {
		return <Icons.Youtube />;
	}
};

export const Footer = ({ email, social, texts, menu }: FooterModel.Data) => {
	return (
		<footer className={styles.Footer}>
			<div className={clsx(styles.Footer__Block, styles.Footer__TopBlock)}>
				<Button href={`mailto:${email}`} textVariant="heading_4">
					{email}
				</Button>
				{social && (
					<ul className={styles.Social}>
						{social.map(({ link, type }) => (
							<li key={type}>
								<Button.Social href={link} target="_blank" icon={getSocialIcon(type)} />
							</li>
						))}
					</ul>
				)}
			</div>

			<div className={styles.Politics}>
				<ul className={styles.Politics__List}>
					{menu.map(({ title, href }, index) => (
						<li key={`politics-item-${index}`}>
							<Button
								className={styles.Politics__Link}
								to={href}
								target="_blank"
								textVariant="small"
							>
								{title}
							</Button>
						</li>
					))}
				</ul>
			</div>

			<div className={clsx(styles.Footer__Block, styles.Footer__BottomBlock)}>
				<Typography className={styles.Copyright} as="p" variant="small" html={texts.copyright} />
				<Typography className={styles.Development} as="p" variant="small">
					<Icons.RedCollar />
					Cделано в&nbsp;
					<Button href="https://redcollar.ru/" target="_blank" textVariant="small">
						Red Collar
					</Button>
				</Typography>
			</div>
		</footer>
	);
};
