export const required = 'Поле обязательно для заполнения';

export const inn = 'ИНН должен состоять из 10 или 12 цифр';

export const email = 'E-mail введён некорректно';

export const unknownError = 'Неизвестная ошибка';

export const getRangeLength = (max: number, min?: number) =>
	min
		? `В поле должно быть от ${min} до ${max} символов`
		: `В поле должно быть не более ${max} символов`;
