import React from 'react';
import { Page } from 'shared/api';
import { Slider as SliderUI } from 'shared/ui';

import styles from '../styles.module.scss';

export const Slider = ({ items }: Page.Block.Base.SliderBase) => (
	<SliderUI
		className={styles.Slider}
		items={items.map((item, index) => (
			<SliderUI.Image key={`slider-image-${index}`} src={item.img_src} alt={item.title} />
		))}
	/>
);
