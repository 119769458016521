import React, { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';

const Error = React.lazy(() => import('shared/ui/error'));

export const notFound: RouteObject = {
	path: '*',
	element: (
		<Suspense fallback={null}>
			<Error large title="404" description="Страница не найдена" />
		</Suspense>
	),
};
