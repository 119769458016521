// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_Spinner__GHG\\+p {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 6.5rem;\n  height: 6.5rem;\n  color: #188ecd;\n  padding: 2rem;\n  background: #ffffff;\n  box-shadow: 3px 3px 28px -3px rgba(0, 0, 0, 0.13);\n  border-radius: 16px; }\n  .styles_Spinner__Icon__JDBmc {\n    line-height: 0; }\n    .styles_Spinner__Icon__JDBmc svg {\n      transform-origin: center;\n      animation: styles_loader__u73z- 1.5s linear infinite; }\n\n@keyframes styles_loader__u73z- {\n  0% {\n    transform: rotateZ(0deg); }\n  100% {\n    transform: rotateZ(360deg); } }\n", "",{"version":3,"sources":["webpack://./src/shared/ui/spinner/styles.module.scss","webpack://./src/app/styles/variables.scss"],"names":[],"mappings":"AAGA;EACC,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,aAAa;EACb,cAAc;EACd,cCVe;EDWf,aAAa;EACb,mBCRmB;EDSnB,iDAAiD;EACjD,mBAAmB,EAAA;EAEnB;IACC,cAAc,EAAA;IADd;MAIC,wBAAwB;MACxB,oDAAsC,EAAA;;AAKzC;EACC;IACC,wBAAwB,EAAA;EAGzB;IACC,0BAA0B,EAAA,EAAA","sourcesContent":["@import 'app/styles/variables';\n@import 'shared/styles/mixins';\n\n.Spinner {\n\tposition: absolute;\n\ttop: 50%;\n\tleft: 50%;\n\ttransform: translate(-50%, -50%);\n\twidth: 6.5rem;\n\theight: 6.5rem;\n\tcolor: $accent;\n\tpadding: 2rem;\n\tbackground: $background;\n\tbox-shadow: 3px 3px 28px -3px rgba(0, 0, 0, 0.13);\n\tborder-radius: 16px;\n\n\t&__Icon {\n\t\tline-height: 0;\n\n\t\tsvg {\n\t\t\ttransform-origin: center;\n\t\t\tanimation: loader 1.5s linear infinite;\n\t\t}\n\t}\n}\n\n@keyframes loader {\n\t0% {\n\t\ttransform: rotateZ(0deg);\n\t}\n\n\t100% {\n\t\ttransform: rotateZ(360deg);\n\t}\n}\n","$accent: #188ecd;\n$stroke: #bebebe;\n$text: #313131;\n$second-text: #898989;\n$background: #ffffff;\n$black: #111111;\n$error: #ff004b;\n\n$mobile-start: 320px;\n$mobile-end: 767px;\n$tablet-start: 768px;\n$tablet-end: 1279px;\n$desktop-start: 1280px;\n\n$font-size: 16px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Spinner": "styles_Spinner__GHG+p",
	"Spinner__Icon": "styles_Spinner__Icon__JDBmc",
	"loader": "styles_loader__u73z-"
};
export default ___CSS_LOADER_EXPORT___;
