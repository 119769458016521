import { Modal as AntdModal } from 'antd';
import { useUnit } from 'effector-react';
import React from 'react';
import { Icons, Typography } from 'shared/ui';

import * as model from './model';
import styles from './styles.module.scss';

export interface ModalProps {
	title?: string;
	description?: string;
}

export const Modal = () => {
	const isOpen = useUnit(model.$isOpen);
	const props = useUnit(model.$props);
	const close = useUnit(model.close);

	const handleClose = () => {
		close();
	};

	const titleElement = props?.title && (
		<Typography className={styles.Title} as="h2" variant="heading_2">
			{props.title}
			<span className={styles.Title__Icon}>
				<Icons.Accept size={24} />
			</span>
		</Typography>
	);

	return (
		<AntdModal
			className={styles.Modal}
			centered
			open={isOpen}
			title={titleElement}
			footer={null}
			closeIcon={<Icons.Close />}
			onCancel={handleClose}
		>
			<Typography as="div" variant="large">
				{props?.description}
			</Typography>
		</AntdModal>
	);
};
