import { Anchor } from 'antd';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Header } from 'shared/api';
import { useBreakpoint, useRect } from 'shared/lib/hooks';
import { Typography } from 'shared/ui';

import styles from './styles.module.scss';

interface NavListProps {
	menu: Header.MenuItem[];
	onClose: () => void;
}

export const NavList = ({ menu, onClose }: NavListProps) => {
	const { pathname } = useLocation();

	const [, ref] = useRect<HTMLUListElement>();

	const [hasDropdown, setHasDropdown] = useState(true);

	const isDesktop = useBreakpoint('desktop');

	const linkClassName = (isActive: boolean) =>
		clsx(styles.NavLink, isActive && styles.NavLink_Active);

	useEffect(() => {
		const scrollHandler = () => {
			setHasDropdown(window.scrollY < 20);
		};
		window.addEventListener('scroll', scrollHandler);
		return () => {
			return window.removeEventListener('scroll', scrollHandler);
		};
	}, []);

	return (
		<ul ref={ref} className={styles.NavList}>
			{menu.map((parent) => (
				<li key={parent.id} className={styles.NavList__Item}>
					<NavLink
						className={({ isActive }) => linkClassName(isActive)}
						to={parent.code}
						onClick={onClose}
					>
						<Typography variant="button">{parent.name}</Typography>
					</NavLink>

					{parent.subsections.length > 0 && hasDropdown && (
						<Anchor offsetTop={isDesktop ? 40 : 100} onClick={() => onClose()} affix={false}>
							<div className={styles.Dropdown}>
								<div className={clsx(styles.Dropdown__Content, 'dropdown-content')}>
									<ul
										style={{
											marginLeft: `${ref.current?.getBoundingClientRect().x}px`,
											maxWidth: `${ref.current?.getBoundingClientRect().width}px`,
										}}
										className={styles.Dropdown__List}
									>
										{parent.subsections.map((child) => (
											<li key={child.id}>
												{pathname === `/${parent.code}` ? (
													<Anchor.Link
														className={clsx(styles.Dropdown__Link, styles.Dropdown__AnchorLink)}
														href={`#${child.code}`}
														title={child.name}
													/>
												) : (
													<NavLink
														to={`/${parent.code}#${child.code}`}
														className={styles.Dropdown__Link}
														onClick={onClose}
													>
														<Typography variant="button">{child.name}</Typography>
													</NavLink>
												)}
											</li>
										))}
									</ul>
								</div>
							</div>
						</Anchor>
					)}
				</li>
			))}
		</ul>
	);
};
