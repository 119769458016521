import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd';
import clsx from 'clsx';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import React, { forwardRef } from 'react';

import { Icons } from '../icons';
import styles from './styles.module.scss';

export type SelectProps = AntdSelectProps;

export const Select = forwardRef<BaseSelectRef, SelectProps>(({ className, ...props }, ref) => {
	return (
		<AntdSelect
			ref={ref}
			className={clsx(styles.Select, className)}
			popupClassName={styles.Select__Popup}
			suffixIcon={
				<span className={styles.Select__Icon}>
					<Icons.Arrow />
				</span>
			}
			{...props}
		/>
	);
});

Select.displayName = 'Select';
