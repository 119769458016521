import { CareerForm, SuppliersForm } from 'features';
import React from 'react';
import { BlockTypes, Page } from 'shared/api';

import styles from '../styles.module.scss';

interface FormProps {
	form: Page.Block.Component.Form.Data;
	type: BlockTypes;
}

export const Form = ({ form, type }: FormProps) => {
	const props = {
		form,
		className: styles.Form,
	};

	switch (type) {
		case BlockTypes.ComponentFormSuppliersResponses:
			return <SuppliersForm {...props} />;

		case BlockTypes.ComponentFormCareerResponses:
			return <CareerForm {...props} />;
	}

	return null;
};
