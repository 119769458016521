import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { Form } from 'shared/api';
import { httpClient } from 'shared/api/common';
import { createError } from 'shared/lib/effector';
import { schemes } from 'shared/lib/schemes';
import * as modal from 'widgets/modal/model';
import * as notificationModel from 'widgets/notification/model';

interface FormRequest extends Form.Request {
	withFile?: boolean;
}

const {
	messages: { unknownError },
} = schemes;

const submit = (
	actionLink: string,
	data: unknown,
	withFile: boolean,
	config?: AxiosRequestConfig
) => httpClient(withFile).post<Form.Response>(actionLink, data, config);

export const submitFormFx = createEffect(
	async ({ actionLink, data, withFile = false }: FormRequest) => submit(actionLink, data, withFile)
);

sample({
	clock: submitFormFx.doneData,
	fn: (clock) => {
		const { title, description } = clock.data.details;
		return { title, description };
	},
	target: modal.open,
});

const $error = createError(submitFormFx);

sample({
	clock: $error,
	filter: (error): error is AxiosError['response'] | null =>
		error !== null && error?.status !== 400,
	fn: () => unknownError,
	target: notificationModel.showError,
});

export const $validationErrors = createStore<Form.ValidationErrors | null>(null);

export const resetValidationError = createEvent<string>();

sample({
	clock: $error,
	filter: (error): error is AxiosResponse<unknown, never> | null | undefined => !!error,
	fn: (error) => {
		const { details } = error?.data as Form.Response;
		return details;
	},
	target: $validationErrors,
});

sample({
	source: $validationErrors,
	clock: resetValidationError,
	fn: (errors, key) => {
		delete errors?.[key];
		return { ...errors };
	},
	target: $validationErrors,
});
