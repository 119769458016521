import clsx from 'clsx';
import React from 'react';
import { BaseProps } from 'shared/types';

import { Button } from '../button';
import { AllButtonProps } from '../button/ui';
import { Icons } from '../icons';
import { Typography } from '../typography';
import styles from './styles.module.scss';

type ContactLinkProps = AllButtonProps & {
	linkType?: string;
};

const ContactLink = ({ linkType, ...props }: ContactLinkProps) => (
	<Button
		insideText
		textVariant="large"
		underlineOnHover
		icon={linkType === 'internal' ? <Icons.AngleArrow size={16} /> : undefined}
		{...props}
	/>
);

interface ContactCardProps extends BaseProps {
	Link?: AllButtonProps;
	description: React.ReactNode;
}

export const ContactCard = ({ description, children, className }: ContactCardProps) => {
	return (
		<div className={clsx(styles.ContactCard, className)}>
			<Typography className={styles.ContactCard__Description} variant="medium">
				{description}
			</Typography>
			<div className={styles.ContactCard__List}>{children}</div>
		</div>
	);
};

ContactCard.Link = ContactLink;
