import clsx from 'clsx';
import React from 'react';
import { Icons } from 'shared/ui/icons';

import { AllButtonProps, Button } from '../ui';
import styles from './styles.module.scss';

export const OpenButton = ({ className, ...props }: AllButtonProps) => {
	return (
		<Button
			className={clsx(styles.OpenButton, className)}
			appearance="icon"
			icon={<Icons.Arrow />}
			{...props}
		/>
	);
};
