import clsx from 'clsx';
import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { BaseProps } from 'shared/types';
import { Icons, Typography } from 'shared/ui';

import { TypographyStyle } from '../typography/types';
import { ArrowButton, ArrowButtonProps } from './arrow';
import { OpenButton } from './open';
import { SocialButton } from './social';
import styles from './styles.module.scss';

type ButtonAppearance = 'link' | 'outlined' | 'icon';

type ButtonBaseProps = ComponentPropsWithoutRef<'button'>;
type AnchorProps = ComponentPropsWithoutRef<'a'>;

type ButtonUnionProps =
	| Omit<ButtonBaseProps, 'className'>
	| Omit<LinkProps, 'className'>
	| Omit<AnchorProps, 'className'>;

export interface ButtonProps extends ButtonComponentProps {
	Arrow: ArrowButtonProps;
	Social: ButtonComponentProps;
	Open: ButtonComponentProps;
}

interface ButtonComponentProps extends BaseProps {
	icon?: ReactNode;
	appearance?: ButtonAppearance;
	fullWidth?: boolean;
	insideText?: boolean;
	textVariant?: TypographyStyle;
	underlineOnHover?: boolean;
}

export type AllButtonProps = ButtonComponentProps & ButtonUnionProps;

const isButton = (props: ButtonUnionProps): props is ButtonBaseProps =>
	!('href' in props) && !('to' in props);

export const Button = ({
	children,
	className,
	icon,
	appearance = 'link',
	fullWidth = false,
	insideText = false,
	textVariant = 'button',
	underlineOnHover = false,
	...props
}: AllButtonProps) => {
	let content = null;

	if (appearance === 'icon') {
		content = <span className={styles.Button_Appearance_icon__Icon}>{icon}</span>;
	} else {
		let iconElement = null;

		if (appearance === 'outlined') {
			iconElement = (
				<span className={styles.Button_Appearance_outlined__Icon}>
					{icon ?? <Icons.AngleArrow size={16} />}
				</span>
			);
		} else if (appearance === 'link' && icon) {
			iconElement = <span className={styles.Button_Appearance_link__Icon}>{icon}</span>;
		} else if (icon) {
			iconElement = <span>{icon}</span>;
		}

		content = (
			<Typography className={styles.Button__Content} variant={textVariant}>
				{children && <span dangerouslySetInnerHTML={{ __html: children.toString() }} />}
				{iconElement}
			</Typography>
		);
	}

	const elementClassName = clsx(
		styles.Button,
		styles[`Button_Appearance_${appearance}`],
		fullWidth && styles.Button_FullWidth,
		insideText && styles.Button_InsideText,
		underlineOnHover && styles.Button_UnderlineOnHover,
		className
	);

	if ('href' in props) {
		return (
			<a className={elementClassName} {...props}>
				{content}
			</a>
		);
	}

	if ('to' in props) {
		return (
			<Link className={elementClassName} {...props}>
				{content}
			</Link>
		);
	}

	if (isButton(props)) {
		return (
			<button className={elementClassName} {...props}>
				{content}
			</button>
		);
	}

	return null;
};

Button.Arrow = ArrowButton;
Button.Social = SocialButton;
Button.Open = OpenButton;
