import clsx from 'clsx';
import React from 'react';
import { BlockTypes, Page } from 'shared/api';

import styles from '../styles.module.scss';

export const List = ({ items, type }: Page.Block.Base.List & { type: BlockTypes }) => {
	const order = type === BlockTypes.OrderedList;
	const children = items.map((item, index) => (
		<li key={`list-item-${index}`} dangerouslySetInnerHTML={{ __html: item.text }} />
	));

	return order ? (
		<ol className={clsx(styles.List, styles.List_Order)}>{children}</ol>
	) : (
		<ul className={styles.List}>{children}</ul>
	);
};
