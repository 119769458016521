import clsx from 'clsx';
import React from 'react';
import { Typography, TypographyProps } from 'shared/ui';

import styles from '../styles.module.scss';

interface ParagraphProps extends TypographyProps {
	block?: boolean;
}

export const Paragraph = ({ block = false, variant, ...props }: ParagraphProps) => (
	<Typography
		className={clsx(styles.Paragraph, block && styles.Paragraph_Block)}
		as="p"
		variant={variant}
		{...props}
	/>
);
