import { Input, InputProps, InputRef } from 'antd';
import clsx from 'clsx';
import React, { ChangeEvent, forwardRef, useEffect, useState } from 'react';

import { Icons } from '../icons';
import { Typography } from '../typography';
import styles from './styles.module.scss';

interface SearchInputProps extends InputProps {
	onClear?: () => void;
	errorText?: string;
}

export const SearchInput = forwardRef<InputRef, SearchInputProps>(
	(
		{ className, placeholder = 'Начните поиск', errorText, value, onChange, onClear, ...props },
		ref
	) => {
		const [currValue, setCurrValue] = useState(value);

		const hasValue = !!currValue;

		const handleClearValue = () => {
			setCurrValue('');
			onClear?.();
		};

		const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
			setCurrValue(event.target.value);
			onChange?.(event);
		};

		useEffect(() => {
			setCurrValue(value);
		}, [value]);

		const clearButton = (
			<button onClick={handleClearValue}>
				<Icons.Close />
			</button>
		);

		return (
			<div className={clsx(styles.Wrapper, className)}>
				<Input
					ref={ref}
					className={styles.Input}
					suffix={hasValue ? clearButton : <Icons.Search />}
					placeholder={placeholder}
					value={currValue}
					onChange={handleChange}
					{...props}
				/>

				{errorText && (
					<Typography className={styles.ErrorText} variant="extra_small" as="p" html={errorText} />
				)}
			</div>
		);
	}
);

SearchInput.displayName = 'SearchInput';
