import clsx, { ClassValue } from 'clsx';
import React, { forwardRef } from 'react';

import styles from './styles.module.scss';
import type { TypographyStyle } from './types';

export type TypographyElementType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';

export interface TypographyProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'className'> {
	variant?: TypographyStyle;
	as?: TypographyElementType;
	line?: boolean;
	className?: ClassValue;
	children?: React.ReactNode;
	html?: string;
}

export const Typography = forwardRef<HTMLDivElement, TypographyProps>(
	(
		{ as = 'span', variant = 'medium', line = false, className, children, html, ...attributes },
		ref
	) => {
		const Element = as;

		if (html) {
			const lineElement = line ? '<span class="typography-line"></span>&nbsp;' : '';

			return (
				<Element
					ref={ref}
					className={clsx(styles[variant], className)}
					{...attributes}
					dangerouslySetInnerHTML={{ __html: lineElement + html }}
				></Element>
			);
		}

		return (
			<Element className={clsx(styles[variant], className)} {...attributes}>
				{line && (
					<>
						<span className="typography-line" />
						<>&nbsp;</>
					</>
				)}
				{children}
			</Element>
		);
	}
);

Typography.displayName = 'Typography';
