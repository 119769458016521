import { Image as AntdImage } from 'antd';
import clsx from 'clsx';
import React, { useState } from 'react';
import { DefaultStyledProps } from 'shared/types';
import { Typography } from 'shared/ui';

import styles from './styles.module.scss';
import { getOrientation, ImageOrientation } from './utils';

export interface ImageProps {
	src: string;
	caption: string;
}

interface ImageComponentProps extends DefaultStyledProps, ImageProps {
	getImageOrientation: (value: ImageOrientation | undefined) => void;
}

export const Image = ({ src, caption, className, getImageOrientation }: ImageComponentProps) => {
	const [orientation, setOrientation] = useState<ImageOrientation | undefined>(undefined);

	const handleLoadImage = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
		const image = event.target as HTMLImageElement;
		const imageOrientation = getOrientation(image);
		setOrientation(imageOrientation);
		getImageOrientation?.(imageOrientation);
	};

	return (
		<div className={clsx(styles.Wrapper, className)}>
			<AntdImage
				className={clsx(styles.Image, styles[`Image_${orientation}`])}
				src={src}
				alt={caption}
				onLoad={handleLoadImage}
			/>
			<div className={styles.Caption}>
				<Typography className={styles.Caption__Text} variant="small">
					{caption}
				</Typography>
			</div>
		</div>
	);
};
