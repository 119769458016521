import { Form as AntdForm } from 'antd';
import clsx from 'clsx';
import { useUnit } from 'effector-react';
import React from 'react';
import { Control, FieldValues, useFormContext } from 'react-hook-form';
import { Form as FormModel, Page } from 'shared/api';
import { DefaultStyledProps } from 'shared/types';
import { Button, FormItem, Icons } from 'shared/ui';

import * as model from './model';
import styles from './styles.module.scss';
import { removeEmptyFields, ResetServerErrorFunc } from './utils';

export interface FormProps extends DefaultStyledProps {
	withFile?: boolean;
	form: Page.Block.Component.Form.Data;
	getFieldComponent: (
		field: Page.Block.Component.Form.FieldConfig,
		control: Control<FieldValues>,
		validationServerErrors: FormModel.ValidationErrors | null,
		resetServerError: ResetServerErrorFunc
	) => JSX.Element | null;
	prepareDataForSending?: (data: unknown) => unknown;
}

export const Form = ({
	className,
	withFile,
	form: { action, fields },
	getFieldComponent,
	prepareDataForSending,
}: FormProps) => {
	const { control, handleSubmit, reset } = useFormContext();

	const validationServerErrors = useUnit(model.$validationErrors);
	const resetValidationServerError = useUnit(model.resetValidationError);

	const onSubmit = async (fields: unknown) => {
		const data = prepareDataForSending ? prepareDataForSending(fields) : fields;
		await model.submitFormFx({ actionLink: action, data: removeEmptyFields(data), withFile });
		reset();
	};

	return (
		<AntdForm className={clsx(styles.Form, className)} layout="vertical">
			{Object.values(fields).map((field) => (
				<React.Fragment key={field.name}>
					{getFieldComponent(field, control, validationServerErrors, resetValidationServerError)}
				</React.Fragment>
			))}

			<FormItem className={styles.Form__SubmitBtn}>
				<Button
					fullWidth
					appearance="outlined"
					icon={<Icons.Post />}
					onClick={handleSubmit(onSubmit)}
				>
					{fields.submit.label}
				</Button>
			</FormItem>
		</AntdForm>
	);
};
