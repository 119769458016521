import { Radio, RadioGroupProps as AntdRadioGroupProps } from 'antd';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

import styles from './styles.module.scss';

export type RadioGroupProps = AntdRadioGroupProps;

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
	({ optionType = 'default', className, children, ...props }, ref) => {
		return (
			<Radio.Group
				{...props}
				className={clsx(styles.RadioGroup, className)}
				optionType={optionType}
				ref={ref}
			/>
		);
	}
);

RadioGroup.displayName = 'RadioGroup';
