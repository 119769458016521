import { AxiosError } from 'axios';
import { createStore, Effect } from 'effector';

export const createError = <Params, Done>(effect: Effect<Params, Done, Error>) => {
	const $error = createStore<AxiosError['response'] | null>(null);

	$error
		.on(effect.done, () => null)
		.on(effect.fail, (_, response) => (response.error as AxiosError)['response']);

	return $error;
};
