import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { FormItem, MaskedInput, MaskedInputProps } from 'shared/ui';

import { FormFieldWrapper } from '../utils';

type Props<T extends FieldValues> = FormFieldWrapper<MaskedInputProps, T>;

export const FormMaskedInput = <T extends object>({
	control,
	name,
	defaultValue,
	label,
	fullWidth,
	className,
	serverError,
	resetServerError,
	...props
}: Props<T>) => {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field, fieldState: { error } }) => {
				const fieldPrepareType = field as unknown as MaskedInputProps;
				return (
					<FormItem
						className={className}
						label={label}
						fullWidth={fullWidth}
						error={error}
						serverError={serverError}
					>
						<MaskedInput
							{...props}
							{...fieldPrepareType}
							onChange={(event) => {
								serverError && resetServerError(name);
								fieldPrepareType.onChange?.(event);
							}}
						/>
					</FormItem>
				);
			}}
		/>
	);
};
