import { string } from 'yup';

import * as messages from './messages';
import * as regexp from './regexp';

export const stringWithoutSpaces = string().trim();

export const requiredString = stringWithoutSpaces.required(messages.required);

export const email = requiredString.email(messages.email);

export const inn = requiredString.matches(regexp.inn, messages.inn);
