import { createEffect, createStore, sample } from 'effector';
import { componentsApi, Footer } from 'shared/api';

export const getFooterFx = createEffect(componentsApi.getFooter);

export const $footer = createStore<Footer.Data | null>(null);

sample({
	clock: getFooterFx.doneData,
	fn: (clock) => clock.data,
	target: $footer,
});
