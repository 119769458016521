import { Anchor } from 'antd';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link as LinkNavigate, useLocation, useNavigate } from 'react-router-dom';
import { Header } from 'shared/api';
import { useBreakpoint } from 'shared/lib/hooks';
import { BackTo } from 'shared/types';
import { Icons, Typography } from 'shared/ui';

import styles from './styles.module.scss';

interface SidebarProps {
	navigation?: Header.MenuItem;
	backTo?: BackTo;
}

const { Link } = Anchor;

export const Sidebar = ({ navigation, backTo }: SidebarProps) => {
	const navigate = useNavigate();
	const { hash } = useLocation();

	const [activeLink, setActiveLink] = useState('');

	const getAnchor = (code: string) => `#${code}`;

	const handleChange = (currentActiveLink: string) => {
		setActiveLink(currentActiveLink);
	};

	const isDesktop = useBreakpoint('desktop');

	const to = navigation?.code && activeLink ? `${navigation.code}${activeLink}` : '';

	useEffect(() => {
		const code = hash.slice(1);
		const elem = navigation?.subsections.find((item) => item.code === code);
		if (elem) {
			setActiveLink(hash);
			const link = document.querySelectorAll(`a[href='${hash}']`)[0] as HTMLElement;
			link?.click();
		} else {
			const rect = document.getElementById(code)?.getBoundingClientRect();
			if (rect) {
				const top = rect.top - rect.height;
				window.scroll({ top, behavior: 'smooth' });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (to && !backTo) {
			navigate(to);
		}
	}, [to, backTo, navigate]);

	if (backTo) {
		return (
			<div className={styles.Sidebar}>
				<Typography className={styles.Sidebar__Header} variant="large">
					{backTo.title}
				</Typography>
				<div className={styles.Sidebar__Body}>
					<ul className={styles.SectionList}>
						<li>
							<LinkNavigate to={backTo.pathname} className={styles.BackTo}>
								<span className={styles.BackTo__Icon}>
									<Icons.ArrowDown />
								</span>
								Вернуться назад
							</LinkNavigate>
						</li>
					</ul>
				</div>
			</div>
		);
	}

	return (
		<div className={styles.Sidebar}>
			<Anchor offsetTop={isDesktop ? 40 : 140} onChange={handleChange} affix={false}>
				<Typography className={styles.Sidebar__Header} variant="large">
					{navigation?.name}
				</Typography>
				<div className={styles.Sidebar__Body}>
					{navigation?.subsections && (
						<ul className={styles.SectionList}>
							{navigation.subsections.map(({ id, code, name }) => (
								<li key={id}>
									<Link
										href={getAnchor(code)}
										title={
											<span className={styles.Sidebar__Link}>
												<span
													className={clsx(
														styles.Line,
														activeLink === getAnchor(code) && styles.Line_Active
													)}
												/>{' '}
												{name}
											</span>
										}
									/>
								</li>
							))}
						</ul>
					)}
				</div>
			</Anchor>
		</div>
	);
};
