import clsx from 'clsx';
import React from 'react';
import { DefaultStyledProps } from 'shared/types';

import { Typography } from '../typography';
import styles from './styles.module.scss';

interface FootnotesProps extends DefaultStyledProps {
	items: { number: string; text: string }[];
}

export const Footnotes = ({ items, className }: FootnotesProps) => {
	return (
		<ul className={clsx(styles.Footnotes, className)}>
			{items.map((item, index) => (
				<li key={`footnote-${index}`} className={styles.Footnotes__ItemText}>
					<Typography
						className={styles.Footnotes__ItemNumber}
						as="p"
						variant="small"
						html={item.number}
					/>
					<Typography as="p" variant="small" html={item.text} />
				</li>
			))}
		</ul>
	);
};
