const isDevelopment = process.env.NODE_ENV === 'development';

export const download = (url: string, name?: string) => {
	const relativePath = isDevelopment ? url.replace(process.env.REACT_APP_API_URL || '', '') : url;

	const target = isDevelopment ? {} : { target: '_blank' };

	return {
		href: relativePath,
		download: name || '',
		...target,
	};
};
