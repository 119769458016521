import { FormCheckbox as Checkbox } from './checkbox';
import { FormInput as Input } from './input';
import { FormMaskedInput as MaskedInput } from './masked-input';
import { FormRadioGroup as RadioGroup } from './radio-group';
import { FormSelect as Select } from './select';
import { FormTextArea as TextArea } from './textarea';
import { Form as BaseForm } from './ui';
import { FormUpload as Upload } from './upload';

export type { FormProps } from './ui';
export type { ResetServerErrorFunc } from './utils';
export { getServerError } from './utils';

export const Form = {
	BaseForm,
	Input,
	MaskedInput,
	Checkbox,
	Select,
	Upload,
	TextArea,
	RadioGroup,
};
