export type ImageOrientation = 'vertical' | 'horizontal';

export const getOrientation = (image: HTMLImageElement | null): ImageOrientation | undefined => {
	if (!image) {
		return undefined;
	}

	const width = image.naturalWidth;
	const height = image.naturalHeight;

	return width > height ? 'horizontal' : 'vertical';
};
