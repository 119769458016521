const news = {
	patternID: '/news/:id',

	getNewsUrl: (id: string) => `/news/${id}`,
};

export const paths = {
	main: '/',
	about: '/about',
	basePatternID: ':code',
	esgStrategy: '/esg-strategy',
	nicotineIndustry: '/nicotine-industry',
	suppliers: '/suppliers',
	career: '/career',
	pressOffice: '/press-office',
	contacts: '/contacts',
	archive: '/archive',
	news,
};
