import { notification } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { Icons } from 'shared/ui';

import styles from './styles.module.scss';
import { Notification } from './types';

export const notify = ({ message, type }: Notification) =>
	notification.open({
		message,
		duration: 3,
		placement: 'bottomRight',
		icon: type === 'error' ? <Icons.Attention /> : <Icons.Accept />,
		closeIcon: <Icons.Close size={13} />,
		className: clsx(styles.Notification, styles[`Notification_${type}`]),
	});
