import clsx from 'clsx';
import React from 'react';
import { DefaultStyledProps } from 'shared/types';

import { Icons } from '../icons';
import styles from './styles.module.scss';

export const Spinner = ({ className }: DefaultStyledProps) => {
	return (
		<div className={clsx(styles.Spinner, className)}>
			<span className={styles.Spinner__Icon}>
				<Icons.Loader />
			</span>
		</div>
	);
};
