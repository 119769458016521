import clsx from 'clsx';
import { Form, ResetServerErrorFunc } from 'features/forms/form';
import React, { useCallback, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Page } from 'shared/api';
import { Icons, Typography } from 'shared/ui';

import styles from './styles.module.scss';

const { Input, MaskedInput } = Form;

const DEFAULT_VALUE = {
	tobacco_company_name_with_which_company_worked: '',
	period_of_time_during_which_company_worked_with_tobacco_company: '',
};

const MAX_NUMBER_FIELDS = 15;

interface CompaniesInfoProps {
	nameFieldConfig: Page.Block.Component.Form.FieldConfig;
	timePeriodFieldConfig: Page.Block.Component.Form.FieldConfig;
	resetServerError: ResetServerErrorFunc;
}

export const CompaniesInfo = ({
	nameFieldConfig,
	timePeriodFieldConfig,
	resetServerError,
}: CompaniesInfoProps) => {
	const { control, getValues } = useFormContext();

	const { fields, append, remove } = useFieldArray({
		keyName: 'key',
		control,
		name: 'companies.array',
		shouldUnregister: true,
	});

	const addField = useCallback(() => {
		append(DEFAULT_VALUE);
	}, [append]);

	const deleteField = useCallback(
		(index: number) => {
			remove(index);
		},
		[remove]
	);

	useEffect(() => {
		const numberOfCompanies = getValues().companies.array.length;
		if (numberOfCompanies === 0) {
			addField();
		}
	}, [getValues, addField]);

	return (
		<div className={styles.CompaniesInfo}>
			{fields.map((field, index) => (
				<div className={styles.CompaniesInfo__Row} key={field.key}>
					<div className={styles.CompaniesInfo__Caption}>
						<Typography variant="extra_small_bold">{nameFieldConfig.label}</Typography>
						{fields.length > 1 && (
							<button
								className={styles.CompaniesInfo__DeleteBtn}
								onClick={() => deleteField(index)}
							>
								Удалить
								<Icons.Trash />
							</button>
						)}
					</div>

					<div className={styles.CompaniesInfo__Fields}>
						<Input
							className={styles.CompaniesInfo__Field}
							control={control}
							name={`companies.array.${index}.${nameFieldConfig.name}`}
							placeholder={nameFieldConfig.placeholder}
							resetServerError={resetServerError}
						/>

						<MaskedInput
							className={clsx(styles.CompaniesInfo__Field, styles.CompaniesInfo__FieldTimePeriod)}
							control={control}
							name={`companies.array.${index}.${timePeriodFieldConfig.name}`}
							mask={'00.00.00{ - }00.00.00'}
							placeholder={timePeriodFieldConfig.placeholder}
							resetServerError={resetServerError}
						/>
					</div>
				</div>
			))}

			{fields.length < MAX_NUMBER_FIELDS && (
				<button className={styles.CompaniesInfo__AddBtn} onClick={addField}>
					Добавить ещё
					<Icons.Plus />
				</button>
			)}
		</div>
	);
};
