import clsx from 'clsx';
import { useUnit } from 'effector-react';
import React from 'react';
import { isLoading } from 'shared/lib/effector';
import { SearchInput, Spinner, Typography } from 'shared/ui';

import * as model from './model';
import { SearchResultItem } from './result-item';
import styles from './styles.module.scss';

interface SearchProps {
	visible?: boolean;
	onClose: () => void;
}

export const Search = ({ visible = false, onClose }: SearchProps) => {
	const results = useUnit(model.$results);
	const status = useUnit(model.$status);
	const errorText = useUnit(model.$errorText);
	const search = useUnit(model.search);
	const clear = useUnit(model.clear);
	const setError = useUnit(model.setError);

	const loading = isLoading(status);

	const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.code !== 'Enter') {
			return;
		}

		const value = (event.target as HTMLInputElement).value.trim();
		if (value.length === 0) {
			setError('Поле не заполнено');
		} else if (value.length < 3) {
			setError('Минимальное число символов в строке запроса: 3');
		} else {
			search(value);
		}
	};

	const handleClear = () => {
		clear();
	};

	const handleChange = () => {
		clear();
	};

	return (
		<div
			className={clsx(
				styles.Search,
				visible && styles.Search_Visible,
				!visible && styles.Search_Invisible
			)}
		>
			<div className={styles.Container}>
				<SearchInput
					className={styles.Input}
					disabled={loading}
					errorText={errorText}
					onChange={handleChange}
					onKeyUp={handleKeyUp}
					onClear={handleClear}
				/>

				{loading ? (
					<Spinner />
				) : (
					<div className={styles.SearchResults}>
						{results?.details && (
							<Typography variant="heading_4" as="h4" html={results.details.description} />
						)}

						<ul className={styles.SearchResults__List}>
							{results?.categories?.map((category, index) => (
								<SearchResultItem key={index} item={category} onClose={onClose} />
							))}
						</ul>
					</div>
				)}
			</div>
		</div>
	);
};
