import React from 'react';
import { Page } from 'shared/api';
import { Quote, Typography } from 'shared/ui';

import styles from '../styles.module.scss';

export const BlockquoteBase = ({ items }: Page.Block.Base.BlockquoteBase) => (
	<Typography className={styles.Title_Line} line as="h3" variant="heading_4_paragraph">
		{items.map((item, index) => (
			<React.Fragment key={`heading-accent-${index}`}>
				<span dangerouslySetInnerHTML={{ __html: item.text }} />
				<br />
			</React.Fragment>
		))}
	</Typography>
);

export const BlockquoteExtended = ({
	author_name,
	author_description,
	paragraphs,
}: Page.Block.Base.BlockquoteExtended) => (
	<Quote className={styles.Quote} author={author_name} position={author_description}>
		{paragraphs?.map((item, index) => (
			<React.Fragment key={index}>
				{item.text}
				<br />
			</React.Fragment>
		))}
	</Quote>
);
