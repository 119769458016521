import React from 'react';
import { Search } from 'shared/api';
import { Button } from 'shared/ui';

import styles from './styles.module.scss';

interface SearchResultItemProps {
	item: Search.Category;
	onClose: () => void;
}

export const SearchResultItem = ({
	item: { title, url, items },
	onClose,
}: SearchResultItemProps) => {
	return (
		<li className={styles.SearchResultItem} onClick={onClose}>
			<Button to={url} textVariant="heading_4_paragraph">
				{title}
			</Button>

			{items && items.length > 0 && (
				<ul className={styles.SearchResultItem__Childs}>
					{items.map((item, index) => (
						<li key={index}>
							<Button to={item.url} appearance="link" onClick={onClose}>
								{item.title}
							</Button>
						</li>
					))}
				</ul>
			)}
		</li>
	);
};
