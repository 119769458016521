import React from 'react';
import { Page } from 'shared/api';
import { PreviewFile, Typography } from 'shared/ui';

import styles from './styles.module.scss';

type MediaFilesList = Page.Block.Component.MediaLibraryLogosList['items'];

interface MediaFilesProps {
	title: string;
	items: MediaFilesList;
}

export const MediaFiles = ({ title, items }: MediaFilesProps) => {
	const middle = Math.ceil(items.length / 2);

	const column = (files: MediaFilesList) =>
		files.map((item) => (
			<PreviewFile key={item.file_name} href={item.file_src} name={item.file_name} />
		));

	return (
		<div className={styles.MediaFiles}>
			<Typography className={styles.Title} as="h5" variant="heading_4" html={title} />
			<div className={styles.Content}>
				<div className={styles.Column}>{column(items.slice(0, middle))}</div>
				<div className={styles.Column}>{column(items.slice(middle))}</div>
			</div>
		</div>
	);
};
