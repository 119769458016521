import clsx from 'clsx';
import React from 'react';
import { paths } from 'shared/lib/paths';
import { Button, Icons, Typography } from 'shared/ui';

import styles from './styles.module.scss';

interface ErrorProps {
	large?: boolean;
	title?: string;
	description?: string;
}

const Error = ({
	large = false,
	title = 'Произошла ошибка',
	description = 'Повторите попытку или вернитесь на главную',
}: ErrorProps) => {
	return (
		<div className={clsx(styles.Error, large && styles.Error_Large)}>
			<Typography className={styles.Error__Title} as="h2" variant="heading_1">
				{title}
			</Typography>
			<Typography className={styles.Error__Description} as="p" variant="large">
				{description}
			</Typography>
			<Button
				className={styles.Error__ToMain}
				appearance="outlined"
				fullWidth
				to={paths.main}
				icon={<Icons.Home />}
			>
				Перейти на главную
			</Button>
		</div>
	);
};

export default Error;
