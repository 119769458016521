import { Checkbox as AntdCheckbox, CheckboxProps as AntdCheckboxProps } from 'antd';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

import styles from './styles.module.scss';

export type CheckboxProps = AntdCheckboxProps;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
	({ children, value, className, ...props }, ref) => {
		return (
			<AntdCheckbox
				{...props}
				checked={value}
				ref={ref}
				className={clsx(styles.Checkbox, className)}
			>
				<span dangerouslySetInnerHTML={{ __html: children?.toString() || '' }} />
			</AntdCheckbox>
		);
	}
);

Checkbox.displayName = 'Checkbox';
