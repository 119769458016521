import { Form, getServerError, ResetServerErrorFunc } from 'features/forms/form';
import React from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { Form as FormModel, Page } from 'shared/api';
import { schemes } from 'shared/lib/schemes';
import { Asserts } from 'yup';

import { schema } from './schema';
import styles from './styles.module.scss';
import { WorkExperience } from './work-experience';

type FormFields = Asserts<typeof schema>;

type FormFieldsKey = keyof FormFields;

const { Input, MaskedInput, Checkbox, TextArea, Upload, Select } = Form;

const {
	regexp: { onlyDigit },
} = schemes;

export const getFieldComponent = (
	{ name, label, placeholder, options }: Page.Block.Component.Form.FieldConfig,
	control: Control<FieldValues>,
	errors: FormModel.ValidationErrors | null,
	fields: Page.Block.Component.Form.Field,
	resetServerError: ResetServerErrorFunc
) => {
	const fieldName = name as FormFieldsKey;
	const commonProps = {
		control,
		name: fieldName,
		label,
		placeholder,
		serverError: getServerError(errors, name),
		resetServerError,
	};

	switch (name) {
		case 'company_name':
		case 'company_total_number_of_employees':
			return <Input {...commonProps} maxLength={100} fullWidth={name === 'company_name'} />;

		case 'company_inn':
		case 'company_year_of_registration': {
			const handleInput = (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => {
				event.currentTarget.value = event.currentTarget.value.replace(onlyDigit, '');
			};

			return (
				<Input
					{...commonProps}
					fullWidth={false}
					onInput={handleInput}
					maxLength={name === 'company_year_of_registration' ? 4 : 12}
				/>
			);
		}

		case 'company_email':
			return <Input {...commonProps} fullWidth={false} />;

		case 'company_phone':
			return <MaskedInput {...commonProps} fullWidth={false} mask={'{+7 (}000{) }000-00-00'} />;

		case 'company_site':
			return <Input {...commonProps} fullWidth={false} />;

		case 'company_type': {
			const selectOptions = options?.map(({ text, value }) => ({ label: text, value }));

			return <Select {...commonProps} options={selectOptions} />;
		}

		// case 'company_solutions_and_products':
		case 'company_main_clients':
		case 'company_main_competitors':
		case 'company_additional_information':
		case 'company_annual_turnover':
			return <TextArea {...commonProps} maxLength={2000} />;

		case 'company_products_and_service':
			return (
				<TextArea
					{...commonProps}
					maxLength={2000}
					className={styles.ProductsAndService__Textarea}
				/>
			);

		case 'company_portfolio_file':
			return (
				<Upload
					{...commonProps}
					allowedExtensions={[
						'application/pdf',
						'application/msword',
						'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
					]}
					sizeLimitMb={5}
				/>
			);

		case 'company_has_experience_in_the_tobacco_industry':
			return (
				<WorkExperience
					{...commonProps}
					options={options}
					nameFieldConfig={fields['tobacco_company_name_with_which_company_worked']}
					timePeriodFieldConfig={
						fields['period_of_time_during_which_company_worked_with_tobacco_company']
					}
				/>
			);

		case 'business_rules_confirm':
		case 'personal_agreements_confirm':
			return <Checkbox {...commonProps} />;

		default: {
			return null;
		}
	}
};

interface CompanyData {
	array: {
		tobacco_company_name_with_which_company_worked: string;
		period_of_time_during_which_company_worked_with_tobacco_company: string;
	}[];
}

export const prepareDataForSending = (data: unknown) => {
	const { companies, ...fields } = data as FormFields;

	const companiesArray = (companies as CompanyData)?.array;

	const namesToString = companiesArray?.map(
		(company) => company.tobacco_company_name_with_which_company_worked
	);

	const periodsToString = companiesArray?.map(
		(company) => company.period_of_time_during_which_company_worked_with_tobacco_company
	);

	return {
		'tobacco_company_name_with_which_company_worked[]': namesToString,
		'period_of_time_during_which_company_worked_with_tobacco_company[]': periodsToString,
		...fields,
	};
};
