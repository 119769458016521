import { AxiosRequestConfig } from 'axios';
import { httpClient } from 'shared/api/common';

import { Footer, Header } from './model';

export const getHeader = (config?: AxiosRequestConfig) =>
	httpClient().get<Header.Data>('/components/header', config);

export const getFooter = (config?: AxiosRequestConfig) =>
	httpClient().get<Footer.Data>('/components/footer', config);
