import 'antd/dist/antd.min.css';
import './styles/index.scss';

import { Router } from 'pages/router';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

export const App = () => {
	return <Router />;
};

const WithProviders = () => (
	<BrowserRouter>
		<App />
	</BrowserRouter>
);

export default WithProviders;
