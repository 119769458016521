import { sample } from 'effector';
import { createGate } from 'effector-react';
import * as footerModel from 'shared/entities/footer';
import * as headerModel from 'shared/entities/header';

export const Gate = createGate();

sample({
	clock: Gate.open,
	fn: () => undefined,
	target: [headerModel.getHeaderFx, footerModel.getFooterFx],
});

headerModel.$header.reset(Gate.close);
footerModel.$footer.reset(Gate.close);
