import { MediaFiles } from 'features';
import React from 'react';
import { BlockTypes, Page } from 'shared/api';
import { Button } from 'shared/ui';

import { Content } from './index';

export const getContentComponent = (
	block: Page.Block.Data,
	code?: string,
	className?: string,
	lowerHeading?: boolean
) => {
	switch (block.type) {
		case BlockTypes.Heading_1:
		case BlockTypes.Heading_2:
		case BlockTypes.Heading_3:
		case BlockTypes.Heading_4:
		case BlockTypes.Heading_5: {
			const props = block as Page.Block.Base.Heading;
			const anchorCode = block.type === BlockTypes.Heading_1 ? code : props.code;

			return <Content.Heading id={anchorCode} type={block.type} lower={lowerHeading} {...props} />;
		}

		case BlockTypes.ParagraphList:
		case BlockTypes.ParagraphLargeList: {
			const props = block as Page.Block.Base.ParagraphList;

			return <Content.ParagraphList {...props} type={block.type} />;
		}

		case BlockTypes.OrderedList:
		case BlockTypes.UnorderedList: {
			const props = block as Page.Block.Base.List;

			return <Content.List type={block.type} {...props} />;
		}

		case BlockTypes.BlockquoteBase: {
			const props = block as Page.Block.Base.BlockquoteBase;

			return <Content.BlockquoteBase {...props} />;
		}

		case BlockTypes.BlockquoteExtended: {
			const props = block as Page.Block.Base.BlockquoteExtended;

			return <Content.BlockquoteExtended {...props} />;
		}

		case BlockTypes.Accordion: {
			const props = block as Page.Block.Base.Accordion;

			return <Content.Accordion {...props} />;
		}

		case BlockTypes.SliderBase: {
			const props = block as Page.Block.Base.SliderBase;

			return <Content.Slider {...props} />;
		}

		case BlockTypes.FootnotesList: {
			const props = block as Page.Block.Base.FootnotesList;

			return <Content.FootnotesList {...props} />;
		}

		case BlockTypes.ImageBase: {
			const props = block as Page.Block.Base.ImageBase;

			return <Content.Image {...props} />;
		}

		case BlockTypes.HtmlEditor: {
			const props = block as Page.Block.Base.HtmlEditor;

			return <Content.HtmlEditor {...props} />;
		}

		case BlockTypes.LinkInnerPage: {
			const { title, href, icon_svg } = block as Page.Block.Link.InnerPage;

			return (
				<Button
					to={href}
					insideText
					textVariant="large"
					underlineOnHover
					icon={icon_svg ? <span dangerouslySetInnerHTML={{ __html: icon_svg }} /> : null}
				>
					{title}
				</Button>
			);
		}

		case BlockTypes.LinkExternalWide: {
			const { href, title, is_show_in_new_window } = block as Page.Block.Link.ExternalWide;
			const linkProps = is_show_in_new_window ? { href: href, target: '_blank' } : { to: href };

			return (
				<Content.Paragraph block>
					<Button appearance="outlined" fullWidth {...linkProps}>
						{title}
					</Button>
				</Content.Paragraph>
			);
		}

		case BlockTypes.LinksListWithDelimiter: {
			const props = block as Page.Block.Link.ListWithDelimiter;

			return <Content.LinksListWithDelimiter {...props} className={className} />;
		}

		case BlockTypes.ComponentNewsGroupedByYear: {
			const { data } = block as Page.Block.Component.Data;
			const props = data as Page.Block.Component.NewsGroupedByYear;

			return <Content.GroupedNews {...props} />;
		}

		case BlockTypes.ComponentMediaLibraryLogosList:
		case BlockTypes.ComponentMedialibraryDocumentsList: {
			const { data } = block as Page.Block.Component.Data;
			const { title, items } = data as Page.Block.Component.MediaLibraryLogosList;

			return <MediaFiles title={title} items={items} />;
		}

		case BlockTypes.ComponentMediaLibraryPictureList: {
			const { data } = block as Page.Block.Component.Data;
			const props = data as Page.Block.Component.MediaLibraryPictureList;

			return <Content.PictureList {...props} />;
		}

		case BlockTypes.ComponentFormSuppliersResponses:
		case BlockTypes.ComponentFormCareerResponses: {
			const { data } = block as Page.Block.Component.Data;
			const form = data as Page.Block.Component.Form.Data;

			return <Content.Form form={form} type={block.type} />;
		}
	}
};
