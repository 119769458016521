import { createEffect, createStore, sample } from 'effector';
import { componentsApi, Header } from 'shared/api';

export const getHeaderFx = createEffect(componentsApi.getHeader);

export const $header = createStore<Header.Data | null>(null);

sample({
	clock: getHeaderFx.doneData,
	fn: (clock) => clock.data,
	target: $header,
});
