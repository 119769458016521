// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_Footnotes__-v1zT {\n  display: flex;\n  flex-direction: column;\n  row-gap: 0.5rem;\n  padding-top: 1.5rem;\n  border-top: 1px solid #bebebe; }\n  .styles_Footnotes__ItemText__gKaFI {\n    position: relative;\n    padding-left: 1rem; }\n  .styles_Footnotes__ItemNumber__bowAV {\n    position: absolute;\n    top: -0.4em;\n    left: 0;\n    font-size: 0.9em; }\n", "",{"version":3,"sources":["webpack://./src/shared/ui/footnotes/styles.module.scss","webpack://./src/app/styles/variables.scss"],"names":[],"mappings":"AAEA;EACC,aAAa;EACb,sBAAsB;EACnB,eAAe;EAClB,mBAAmB;EACnB,6BCNe,EAAA;EDQf;IACC,kBAAkB;IAClB,kBAAkB,EAAA;EAGnB;IACC,kBAAkB;IAClB,WAAW;IACX,OAAO;IACP,gBAAgB,EAAA","sourcesContent":["@import 'app/styles/variables';\n\n.Footnotes {\n\tdisplay: flex;\n\tflex-direction: column;\n    row-gap: 0.5rem;\n\tpadding-top: 1.5rem;\n\tborder-top: 1px solid $stroke;\n\n\t&__ItemText {\n\t\tposition: relative;\n\t\tpadding-left: 1rem;\n\t}\n\n\t&__ItemNumber {\n\t\tposition: absolute;\n\t\ttop: -0.4em;\n\t\tleft: 0;\n\t\tfont-size: 0.9em;\n\t}\n}\n","$accent: #188ecd;\n$stroke: #bebebe;\n$text: #313131;\n$second-text: #898989;\n$background: #ffffff;\n$black: #111111;\n$error: #ff004b;\n\n$mobile-start: 320px;\n$mobile-end: 767px;\n$tablet-start: 768px;\n$tablet-end: 1279px;\n$desktop-start: 1280px;\n\n$font-size: 16px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footnotes": "styles_Footnotes__-v1zT",
	"Footnotes__ItemText": "styles_Footnotes__ItemText__gKaFI",
	"Footnotes__ItemNumber": "styles_Footnotes__ItemNumber__bowAV"
};
export default ___CSS_LOADER_EXPORT___;
