import { NewsCardList } from 'features';
import React from 'react';
import { Page } from 'shared/api';
import { TabOption, Tabs } from 'shared/ui';

export const GroupedNews = ({ groups }: Page.Block.Component.NewsGroupedByYear) => {
	if (groups.length === 0) {
		return null;
	}

	if (groups.length === 1) {
		return <NewsCardList news={groups[0].items} />;
	}

	const options: TabOption[] =
		groups.map((group) => {
			return {
				title: group.year,
				content: <NewsCardList news={group.items} />,
			};
		}) || [];

	return <Tabs options={options} />;
};
