import { Anchor } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Header } from 'shared/api';
import { Accordion } from 'shared/ui';

import styles from './styles.module.scss';

interface MobileMenuProps {
	menu: Header.MenuItem[];
	onClose: () => void;
}

export const MobileMenu = ({ menu, onClose }: MobileMenuProps) => {
	const { pathname } = useLocation();

	const linkClassName = (isActive: boolean) => clsx(styles.Link, isActive && styles.Link_Active);

	const isActive = (code: string) => pathname === `/${code}`;

	const headerClassName = (code: string) =>
		clsx(styles.Accordion__Header, isActive(code) && styles.Accordion__Header_Active);

	return (
		<>
			{menu.map(({ name, id, subsections, code }) => {
				if (subsections.length === 0) {
					return (
						<NavLink
							key={id}
							className={({ isActive }) => linkClassName(isActive)}
							to={code}
							onClick={onClose}
						>
							{name}
						</NavLink>
					);
				}

				return (
					<React.Fragment key={id}>
						<Accordion
							isOpen={isActive(code)}
							title={name}
							content={
								<AccordionLinks subsections={subsections} parentCode={code} onClose={onClose} />
							}
							headerClassName={headerClassName(code)}
							iconClassName={styles.Accordion__Icon}
							className={styles.Accordion}
						/>
					</React.Fragment>
				);
			})}
		</>
	);
};

interface AccordionLinksProps {
	subsections: Header.MenuItem[];
	parentCode: string;
	onClose: () => void;
}

const AccordionLinks = ({ subsections, parentCode, onClose }: AccordionLinksProps) => {
	const { pathname, hash } = useLocation();

	const isActive = (path: string) => hash === path;

	const linkClassName = (to: string) =>
		clsx(styles.AccordionLinks__Item, isActive(to) && styles.AccordionLinks__Item_Active);

	return (
		<Anchor offsetTop={100} onClick={() => onClose()} affix={false}>
			<ul className={styles.AccordionLinks}>
				{subsections.map(({ id, code, name }) => {
					const to = pathname.slice(1) === parentCode ? `#${code}` : `/${parentCode}#${code}`;

					return (
						<li key={id}>
							<Anchor.Link className={linkClassName(to)} href={to} title={name} />
						</li>
					);
				})}
			</ul>
		</Anchor>
	);
};
