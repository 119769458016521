import React from 'react';
import { BlockTypes, Page } from 'shared/api';

import { Paragraph } from '../paragraph';

interface ParagraphListProps extends Page.Block.Base.ParagraphList {
	type: BlockTypes;
}

export const ParagraphList = ({ items, type }: ParagraphListProps) => {
	const textVariant = type === BlockTypes.ParagraphLargeList ? 'large' : 'medium';

	return (
		<>
			{items.map((item, index) => (
				<Paragraph key={`paragraph-${index}`} variant={textVariant} html={item.text} />
			))}
		</>
	);
};
