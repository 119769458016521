import clsx from 'clsx';
import { Form, ResetServerErrorFunc } from 'features/forms/form';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Page } from 'shared/api';

import { CompaniesInfo } from './companies-info';
import styles from './styles.module.scss';
import { ExperienceVarinats } from './types';

interface WorkExperienceProps extends Page.Block.Component.Form.FieldConfig {
	resetServerError: ResetServerErrorFunc;
	nameFieldConfig: Page.Block.Component.Form.FieldConfig;
	timePeriodFieldConfig: Page.Block.Component.Form.FieldConfig;
}

const { RadioGroup } = Form;

export const WorkExperience = ({ name, label, options, ...props }: WorkExperienceProps) => {
	const { control, watch } = useFormContext();

	const experience = watch('company_has_experience_in_the_tobacco_industry');
	const hasExperience = experience === ExperienceVarinats.Yes;

	const radioOptions = options?.map((option) => ({ label: option.text, value: option.value }));

	return (
		<>
			<RadioGroup
				className={clsx(hasExperience && styles.Experience_WithExperience__RadioGroup)}
				name={name}
				control={control}
				label={label}
				options={radioOptions}
				resetServerError={props.resetServerError}
			/>

			{hasExperience && <CompaniesInfo {...props} />}
		</>
	);
};
