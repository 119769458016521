import React from 'react';
import { download } from 'shared/lib/utils';

import { Icons } from '../icons';
import { Typography } from '../typography';
import styles from './styles.module.scss';

interface PreviewFileProps {
	href: string;
	name: string;
}

export const PreviewFile = ({ href, name }: PreviewFileProps) => {
	return (
		<a {...download(href, name)} className={styles.PreviewFile}>
			<span className={styles.PreviewFile__Icon}>
				<Icons.File />
			</span>
			<Typography variant="small">{name}</Typography>
		</a>
	);
};
