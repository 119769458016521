import { useEffect, useMemo, useState } from 'react';

export const useIntersectionElements = (
	element: HTMLDivElement | null,
	root: HTMLDivElement | null
) => {
	const [intersection, setIntersection] = useState(false);

	const observer = useMemo(
		() =>
			new IntersectionObserver(([entry]) => setIntersection(entry.isIntersecting), {
				root,
				threshold: 0.2,
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(() => {
		if (element) {
			observer.observe(element);
		}

		return () => {
			observer.disconnect();
		};
	}, [element, observer]);

	return intersection;
};
