// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_SocialButton__BP9BP {\n  width: 48px;\n  height: 48px;\n  color: #188ecd; }\n  .styles_SocialButton__BP9BP:hover, .styles_SocialButton__BP9BP:active {\n    color: #188ecd;\n    box-shadow: 3px 3px 28px -3px rgba(0, 0, 0, 0.13); }\n", "",{"version":3,"sources":["webpack://./src/shared/ui/button/social/styles.module.scss","webpack://./src/app/styles/variables.scss"],"names":[],"mappings":"AAEA;EACC,WAAW;EACX,YAAY;EACT,cCLY,EAAA;EDEhB;IAME,cCRc;IDSd,iDAAiD,EAAA","sourcesContent":["@import 'app/styles/variables';\n\n.SocialButton {\n\twidth: 48px;\n\theight: 48px;\n    color: $accent;\n\n\t&:hover, &:active {\n\t\tcolor: $accent;\n\t\tbox-shadow: 3px 3px 28px -3px rgba(0, 0, 0, 0.13);\n\t}\n}\n","$accent: #188ecd;\n$stroke: #bebebe;\n$text: #313131;\n$second-text: #898989;\n$background: #ffffff;\n$black: #111111;\n$error: #ff004b;\n\n$mobile-start: 320px;\n$mobile-end: 767px;\n$tablet-start: 768px;\n$tablet-end: 1279px;\n$desktop-start: 1280px;\n\n$font-size: 16px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SocialButton": "styles_SocialButton__BP9BP"
};
export default ___CSS_LOADER_EXPORT___;
