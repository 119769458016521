import React from 'react';
import { useRoutes } from 'react-router-dom';
import { MainLayout } from 'widgets';

import { innerPage } from './inner-page';
import { mainPage } from './main-page';
import { notFound } from './not-found';

export const Router = () => {
	return useRoutes([
		{
			path: '',
			element: <MainLayout />,
			children: [mainPage, innerPage, notFound],
		},
	]);
};
