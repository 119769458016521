import clsx from 'clsx';
import { useUnit } from 'effector-react';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import * as headerModel from 'shared/entities/header';
import { isLoading } from 'shared/lib/effector';
import { blockedBodyModel } from 'shared/lib/hooks';
import { Sidebar } from 'widgets/sidebar';

import { pageModel } from './model';
import styles from './styles.module.scss';
import { getBackTo } from './utils';

export const PageLayout = () => {
	const { state, pathname } = useLocation();

	const header = useUnit(headerModel.$header);
	const status = useUnit(pageModel.status);
	const error = useUnit(pageModel.error);
	const layout = useUnit(pageModel.layout);

	const loading = isLoading(status);
	const isBlockedBody = useUnit(blockedBodyModel.isBlockedBody);

	const backTo = getBackTo(pathname, state?.backTo);
	const navigation = header?.menu.find((item) => item.code === pathname.slice(1));

	const hasMenuItems = navigation?.subsections && navigation.subsections.length > 0;
	const showSidebar = hasMenuItems || !!backTo;

	return (
		<div
			className={clsx(
				styles.PageLayout,
				styles[`PageLayout_${layout}`],
				error && styles['PageLayout_full-width']
			)}
		>
			{layout === 'right' && !loading && !error && (
				<div className={styles.PageLayout__Sidebar}>
					{showSidebar && <Sidebar navigation={navigation} backTo={backTo} />}
				</div>
			)}
			<div
				className={clsx(
					styles.PageLayout__Content,
					isBlockedBody && styles.PageLayout__Content_WithBlockedBody
				)}
			>
				<Outlet />
			</div>
		</div>
	);
};
