import { InputProps as AntdInputProps, InputRef } from 'antd';
import { MaskedInput as AntdMaskedInput } from 'antd-mask-input';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

import styles from './styles.module.scss';

type BaseInputProps = Omit<AntdInputProps, 'value | defaultValue'>;

export interface MaskedInputProps extends BaseInputProps {
	mask: React.ComponentProps<typeof AntdMaskedInput>['mask'];
	maskOptions?: React.ComponentProps<typeof AntdMaskedInput>['maskOptions'];
	value?: string;
	defaultValue?: string;
}

export const MaskedInput = forwardRef<InputRef, MaskedInputProps>(
	({ className, ...props }, ref) => {
		return (
			<AntdMaskedInput
				{...props}
				maskOptions={{ lazy: true }}
				className={clsx(styles.MaskedInput, className)}
				ref={ref}
			/>
		);
	}
);

MaskedInput.displayName = 'MaskedInput';
