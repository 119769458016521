import React, { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { paths } from 'shared/lib/paths';
import { PageLayout } from 'widgets';

const BasePage = React.lazy(() => import('pages/base'));
const NewsPage = React.lazy(() => import('pages/news'));

export const innerPage: RouteObject = {
	path: '',
	element: <PageLayout />,
	children: [
		{
			path: paths.basePatternID,
			element: (
				<Suspense fallback={null}>
					<BasePage />
				</Suspense>
			),
		},
		{
			path: paths.news.patternID,
			element: (
				<Suspense fallback={null}>
					<NewsPage />
				</Suspense>
			),
		},
	],
};
