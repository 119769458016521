import { Carousel } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { DefaultStyledProps } from 'shared/types';

import { Button } from '../button';
import styles from './styles.module.scss';

export interface SliderProps extends CarouselProps {
	Image: React.ImgHTMLAttributes<HTMLImageElement>;
}

interface CarouselProps extends DefaultStyledProps {
	items: React.ReactNode[];
	itemClassName?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SlickButton = ({ currentSlide, slideCount, children, ...props }: any) => (
	<span {...props}>{children}</span>
);

export const Slider = ({ items, className, itemClassName }: CarouselProps) => {
	return (
		<div className={clsx(styles.Wrapper, className)}>
			<Carousel
				arrows
				dots
				prevArrow={
					<SlickButton>
						<Button.Open className={styles.Slider__PrevArrow} />
					</SlickButton>
				}
				nextArrow={
					<SlickButton>
						<Button.Open className={styles.Slider__NextArrow} />
					</SlickButton>
				}
			>
				{items.map((item, index) => (
					<div className={clsx(styles.SliderItem, itemClassName)} key={`slider-item-${index}`}>
						{item}
					</div>
				))}
			</Carousel>
		</div>
	);
};

const SliderImage = ({ alt, className, ...props }: React.ImgHTMLAttributes<HTMLImageElement>) => {
	return <img className={clsx(styles.SliderImage, className)} alt={alt} {...props} />;
};

Slider.Image = SliderImage;
