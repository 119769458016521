import { schemes } from 'shared/lib/schemes';
import { bool, object } from 'yup';

const {
	common: { requiredString, email },
	messages: { required },
} = schemes;

export const schema = object({
	full_name: requiredString,
	email,
	personal_agreements_confirm: bool().required(required).oneOf([true], required),
	personal_newsletter_agreements_confirm: bool().required(required).oneOf([true], required),
});
