import { createEvent, createStore, sample } from 'effector';

import { ModalProps } from './ui';

export const open = createEvent<ModalProps | null>();

export const close = createEvent();

export const $isOpen = createStore<boolean>(false);

export const $props = createStore<ModalProps | null>(null);

sample({
	clock: open,
	target: $props,
});

sample({
	clock: open,
	fn: () => true,
	target: $isOpen,
});

sample({
	clock: close,
	fn: () => false,
	target: $isOpen,
});
