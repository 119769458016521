import clsx from 'clsx';
import { Search } from 'features';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Header as HeaderModel } from 'shared/api';
import { useBlockedBody, useBreakpoint } from 'shared/lib/hooks';
import { paths } from 'shared/lib/paths';
import { Icons } from 'shared/ui';

import { HeaderNav } from './header-nav';
import styles from './styles.module.scss';

export const Header = ({ menu }: HeaderModel.Data) => {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [isSearchVisible, setIsSearchVisible] = useState(false);

	const { pathname } = useLocation();
	const isDesktop = useBreakpoint('desktop');
	// const [hasShadow, setHasShadow] = useState(false);

	const isBlockedBody = isMobileMenuOpen || isSearchVisible;
	const isMainPage = pathname === paths.main;
	const hasContainer = isDesktop ? (isMainPage ? true : false) : true;

	useBlockedBody(isBlockedBody, isBlockedBody);

	const handleBurgerClick = () => {
		setIsSearchVisible(false);
		setIsMobileMenuOpen((prevState) => !prevState);
	};

	const handleSearchClick = () => {
		setIsMobileMenuOpen(false);
		setIsSearchVisible((prevState) => !prevState);
	};

	const closeSearch = () => {
		setIsSearchVisible(false);
	};

	const closeMobileMenu = () => {
		setIsMobileMenuOpen(false);
	};

	const closeAll = () => {
		closeSearch();
		closeMobileMenu();
	};

	// useEffect(() => {
	// 	const scrollHandler = () => {
	// 		setHasShadow(window.scrollY > 0);
	// 	};
	// 	window.addEventListener('scroll', scrollHandler);
	// 	return () => {
	// 		return window.removeEventListener('scroll', scrollHandler);
	// 	};
	// }, []);

	return (
		<header className={clsx(styles.Header, isSearchVisible && styles.Header_WithSearch)}>
			<div className={clsx(hasContainer && styles.Container)}>
				<div className={styles.Header__Content}>
					<Link to={paths.main} className={styles.Logo} onClick={closeAll}>
						<Icons.MainLogo />
					</Link>
					<div className={styles.Nav}>
						<HeaderNav menu={menu} isMobileMenuOpen={isMobileMenuOpen} onClose={closeAll} />

						<button
							className={clsx(styles.SearchButton, isSearchVisible && styles.SearchButton_Active)}
							onClick={handleSearchClick}
						>
							{isSearchVisible ? <Icons.Arrow size={24} /> : <Icons.Search />}
						</button>
						<Search visible={isSearchVisible} onClose={closeSearch} />

						<button
							className={clsx(styles.Burger, isMobileMenuOpen && styles.Burger_Active)}
							onClick={handleBurgerClick}
						>
							<span />
						</button>
					</div>
				</div>
			</div>
		</header>
	);
};
