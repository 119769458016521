import clsx from 'clsx';
import { useUnit } from 'effector-react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { News } from 'shared/api';
import * as headerModel from 'shared/entities/header';
import { paths } from 'shared/lib/paths';
import { DefaultStyledProps } from 'shared/types';
import { NewsCardVariant } from 'shared/ui';
import { NewsCard } from 'shared/ui';

import styles from './styles.module.scss';

interface NewsCardListProps extends DefaultStyledProps {
	news: News.Item[];
	variant?: NewsCardVariant;
	parentPageTitle?: string;
}

export const NewsCardList = ({ news, variant, parentPageTitle, className }: NewsCardListProps) => {
	const { pathname } = useLocation();

	const header = useUnit(headerModel.$header);
	const menuItemName = header?.menu.find((item) => item.code === pathname.slice(1))?.name;

	const state = { backTo: { pathname, title: parentPageTitle ?? menuItemName } };

	return (
		<ul className={clsx(styles.NewsCardList, className)}>
			{news.map((item) => (
				<li key={item.id} className={styles.NewsCardList__Item}>
					<NewsCard
						className={styles.NewsCard}
						to={paths.news.getNewsUrl(item.code)}
						state={state}
						title={item.name}
						date={item.date}
						variant={variant}
					/>
				</li>
			))}
		</ul>
	);
};
