import React from 'react';
import { Page } from 'shared/api';
import { Accordion as AccordionUI } from 'shared/ui';

import styles from '../styles.module.scss';
import { getContentComponent } from '../utils';

export const Accordion = ({ title, blocks, is_show_open }: Page.Block.Base.Accordion) => (
	<AccordionUI
		title={title}
		isOpen={is_show_open}
		className={styles.Accordion}
		content={blocks?.map((block, index) => (
			<React.Fragment key={index}>{getContentComponent(block)}</React.Fragment>
		))}
	/>
);
