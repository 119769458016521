// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_Experience_WithExperience__RadioGroup__hVohA {\n  margin-bottom: 1rem; }\n", "",{"version":3,"sources":["webpack://./src/features/forms/suppliers-form/work-experience/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,mBAAmB,EAAA","sourcesContent":[".Experience_WithExperience__RadioGroup {\n\tmargin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Experience_WithExperience__RadioGroup": "styles_Experience_WithExperience__RadioGroup__hVohA"
};
export default ___CSS_LOADER_EXPORT___;
