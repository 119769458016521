import clsx from 'clsx';
import { useGate, useUnit } from 'effector-react';
import React, { useEffect, useRef, useState } from 'react';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import * as footerModel from 'shared/entities/footer';
import * as headerModel from 'shared/entities/header';
import { useIntersectionElements } from 'shared/lib/hooks';
import { paths } from 'shared/lib/paths';
import { Button } from 'shared/ui';
import { Modal } from 'widgets';

import { Footer } from '../footer/ui';
import { Header } from '../header/ui';
import * as model from './model';
import styles from './styles.module.scss';

const PAGES_WITHOUT_CENTER_CONTAINER = [paths.main];
const PAGES_WITH_BUTTON_TO_TOP = [
	paths.about,
	paths.esgStrategy,
	paths.nicotineIndustry,
	paths.suppliers,
	paths.career,
	paths.pressOffice,
];

export const MainLayout = () => {
	useGate(model.Gate);

	const footerRef = useRef<HTMLDivElement | null>(null);
	const buttonRef = useRef<HTMLDivElement | null>(null);
	const intersection = useIntersectionElements(footerRef.current, buttonRef.current);

	const [showButtonTop, setShowButtonTop] = useState(false);

	const { pathname } = useLocation();

	const header = useUnit(headerModel.$header);
	const footer = useUnit(footerModel.$footer);

	const hasCenterContainer = !PAGES_WITHOUT_CENTER_CONTAINER.includes(pathname);
	const hasButtonToTop =
		PAGES_WITH_BUTTON_TO_TOP.includes(pathname) || matchPath(paths.news.patternID, pathname);

	const toTopPage = () => {
		window.scroll({ top: 0, behavior: 'smooth' });
	};

	useEffect(() => {
		const scrollHandler = () => {
			setShowButtonTop(document.body.scrollTop > 30 || document.documentElement.scrollTop > 30);
		};
		window.addEventListener('scroll', scrollHandler);
		return () => {
			return window.removeEventListener('scroll', scrollHandler);
		};
	}, []);

	return (
		<div className={clsx(styles.Wrapper, hasCenterContainer && styles.Wrapper_Center)}>
			{header && <Header {...header} />}

			<main className={styles.Main}>
				{hasButtonToTop && (
					<div
						ref={buttonRef}
						style={{
							bottom: intersection
								? `calc(${footerRef.current?.getBoundingClientRect().height || 0}px + 20px)`
								: '100px',
						}}
						className={clsx(
							styles.ButtonTop_Wrapper,
							showButtonTop && styles.ButtonTop_Wrapper_Show
						)}
					>
						<div className={styles.ButtonTop_Container}>
							<Button.Arrow arrowType="up" className={styles.ButtonTop} onClick={toTopPage} />
						</div>
					</div>
				)}

				<div className={styles.ContentWrapper}>
					<Outlet />
				</div>
			</main>

			{footer && (
				<div ref={footerRef} className={clsx(!hasCenterContainer && styles.Footer)}>
					<Footer {...footer} />
				</div>
			)}

			{/* <div className="blackout" /> */}
			<Modal />
		</div>
	);
};
