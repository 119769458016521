import { News } from 'shared/api';

export enum BlockTypes {
	Heading_1 = 'heading_1',
	Heading_2 = 'heading_2',
	Heading_3 = 'heading_3',
	Heading_4 = 'heading_4',
	Heading_5 = 'heading_5',
	ParagraphList = 'paragraph_list',
	ParagraphLargeList = 'paragraph_large_list',
	BlockquoteBase = 'blockquote_base',
	UnorderedList = 'unordered_list',
	OrderedList = 'ordered_list',
	BlockquoteExtended = 'blockquote_extended',
	SliderBase = 'slider_base',
	Accordion = 'accordion',
	ImageBase = 'image_base',
	FootnotesList = 'footnotes_list',
	HtmlEditor = 'html_editor',
	LinkExternalWide = 'link_external_wide',
	LinksListWithDelimiter = 'links_list_with_delimiter',
	LinkInnerPage = 'link_inner_page',
	ComponentNewsGroupedByYear = 'component_news_grouped_by_year',
	ComponentMediaLibraryLogosList = 'component_medialibrary_logos_list',
	ComponentMediaLibraryPictureList = 'component_medialibrary_picture_list',
	ComponentFormSuppliersResponses = 'component_form_suppliers_responses',
	ComponentFormCareerResponses = 'component_form_career_responses',
	ComponentMedialibraryDocumentsList = 'component_medialibrary_documents_list',
}

export enum PageLayout {
	Left = 'left',
	Right = 'right',
	FullWidth = 'full-width',
}

export declare namespace Page {
	interface Data {
		content: {
			layout: PageLayout;
			sections: Section[];
		};
	}

	interface Section {
		code: string;
		blocks?: Block.Data[];
	}

	namespace Block {
		type Data = (Component.Data | Link.Data | Base.Data) & { type: BlockTypes };

		namespace Component {
			type Data = {
				data: NewsGroupedByYear | MediaLibraryLogosList | MediaLibraryPictureList | Form.Data;
			};
			interface NewsGroupedByYear {
				groups: {
					year: string;
					items: News.Item[];
				}[];
			}

			interface MediaLibraryLogosList {
				title: string;
				items: { file_name: string; file_src: string }[];
			}

			interface MediaLibraryPictureList {
				photo_bank_store_link: { href: string; title: string };
				items: { caption: string; main_picture_src: string; preview_picture_src: string }[];
			}

			namespace Form {
				interface Data {
					action: string;
					fields: Field;
				}

				interface Field {
					[key: string]: FieldConfig;
					submit: FieldConfig;
				}

				interface FieldConfig {
					label?: string;
					name: string;
					placeholder?: string;
					options?: Option[];
				}

				interface Option {
					value: number | string;
					text: string;
					selected?: boolean;
				}
			}
		}

		namespace Link {
			type Data = ExternalWide | ListWithDelimiter | InnerPage;

			interface Base {
				href: string;
				title: string;
				is_show_in_new_window: boolean;
			}

			type ExternalWide = Base;

			interface WithType extends Base {
				link_type: string;
			}

			interface ListWithDelimiter {
				column_width: number;
				title: string;
				paragraphs_list: { text: string }[];
				links_list?: WithType[];
			}

			interface InnerPage extends Base {
				icon_svg?: string;
			}
		}

		namespace Base {
			type Data =
				| Heading
				| ParagraphList
				| BlockquoteBase
				| List
				| BlockquoteExtended
				| SliderBase
				| Accordion
				| FootnotesList
				| ImageBase
				| HtmlEditor;

			interface Text {
				text: string;
				code?: string;
			}

			type Heading = Text;

			interface ParagraphList {
				items: Text[];
			}
			type BlockquoteBase = ParagraphList;
			type List = ParagraphList;

			interface BlockquoteExtended {
				author_name: string;
				author_description: string;
				paragraphs: Text[];
			}

			interface SliderBase {
				items: {
					img_src: string;
					title: string;
				}[];
			}

			interface Accordion {
				title: string;
				is_show_open: boolean;
				blocks?: Block.Data[];
			}

			interface FootnotesList {
				items: { number: string; text: string }[];
			}

			interface ImageBase {
				title: string;
				src: string;
			}

			interface HtmlEditor {
				html: string;
			}
		}
	}
}
