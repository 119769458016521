import axios from 'axios';
export * from './model';

const BASE_URL =
	process.env.NODE_ENV === 'development' ? '/api' : `${process.env.REACT_APP_API_URL}api`;

const BASE_CLIENT = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

const SEND_FILE_CLIENT = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'multipart/form-data',
	},
});

export const httpClient = (withFile?: boolean) => (withFile ? SEND_FILE_CLIENT : BASE_CLIENT);
