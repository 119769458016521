import clsx from 'clsx';
import React from 'react';
import { BaseProps } from 'shared/types';

import { Icons } from '../icons';
import { Typography } from '../typography';
import styles from './styles.module.scss';

export interface QuoteProps extends BaseProps {
	author?: string;
	position?: string;
}

export const Quote = ({ className, author, position, children }: QuoteProps) => {
	return (
		<div className={clsx(styles.Quote, className)}>
			<Typography className={styles.Quote__Name} as="p" variant="large">
				{author}
			</Typography>
			<Typography className={styles.Quote__Position} as="p" variant="small">
				{position}
			</Typography>
			<Typography className={styles.Quote__Text} as="p" variant="large">
				<span className={styles.Quote__Icon}>
					<Icons.Quote />
				</span>
				{children}
			</Typography>
		</div>
	);
};
