import { matchPath } from 'react-router-dom';
import { paths } from 'shared/lib/paths';
import { BackTo } from 'shared/types';

const ARCHIVE_SIDEBAR = {
	pathname: paths.pressOffice,
	title: 'Пресс-офис',
};

const NEWS_PAGE_DEFAULT_SIDEBAR = {
	pathname: paths.main,
	title: 'Главная',
};

const NEWS_PAGE_SIDEBAR = {
	pathname: paths.archive,
	title: 'Архив',
};

export const getBackTo = (pathname: string, backTo?: BackTo) => {
	if (pathname === paths.archive) {
		return ARCHIVE_SIDEBAR;
	}

	if (matchPath(paths.news.patternID, pathname)) {
		if (backTo?.pathname === paths.archive) {
			return NEWS_PAGE_SIDEBAR;
		}

		if (!backTo) {
			return NEWS_PAGE_DEFAULT_SIDEBAR;
		}
	}

	return backTo;
};
