export declare namespace Header {
	interface Data {
		menu: MenuItem[];
	}

	interface MenuItem {
		id: number;
		parent_id: number;
		name: string;
		code: string;
		subsections: MenuItem[];
	}
}

export enum SocialLinkTypes {
	VK = 'vk',
	Youtube = 'youtube',
	Telegram = 'telegram',
}

export declare namespace Footer {
	interface Data {
		email: string;
		social: Social[];
		texts: {
			copyright: string;
		};
		menu: MenuItem[];
	}

	interface Social {
		type: SocialLinkTypes;
		link: string;
	}

	interface MenuItem {
		title: string;
		href: string;
	}
}
