import { Accordion } from './accordion';
import { BlockquoteBase, BlockquoteExtended } from './blockquote';
import { FootnotesList } from './footnotes-list';
import { Form } from './form';
import { GroupedNews } from './grouped-news';
import { Heading } from './heading';
import { HtmlEditor } from './html-editor';
import { Image } from './image';
import { LinksListWithDelimiter } from './links-list-with-delimiter';
import { List } from './list';
import { Paragraph } from './paragraph';
import { ParagraphList } from './paragraph-list';
import { PictureList } from './picture-list';
import { Section } from './section';
import { Slider } from './slider';

export { getContentComponent } from './utils';

export const Content = {
	Accordion,
	BlockquoteBase,
	BlockquoteExtended,
	List,
	Paragraph,
	ParagraphList,
	PictureList,
	GroupedNews,
	Heading,
	LinksListWithDelimiter,
	Slider,
	Section,
	FootnotesList,
	Image,
	Form,
	HtmlEditor,
};
