import { createEffect, createEvent, sample } from 'effector';

import { Notification } from './types';
import { notify } from './ui';

const showNotificationFx = createEffect(({ message, type }: Notification) =>
	notify({ message, type })
);

const show = createEvent<Notification>();

export const showError = show.prepend<string | undefined>((message) => ({
	type: 'error',
	message,
}));

export const showSuccess = show.prepend<string | undefined>((message) => ({
	type: 'success',
	message,
}));

sample({
	clock: show,
	target: showNotificationFx,
});
