import { useEffect, useState } from 'react';

type Device = 'desktop' | 'mobile';

export const MediaQueries: Record<Device, string> = {
	desktop: '(min-width: 1280px)',
	mobile: '(max-width: 767px)',
};

export const useBreakpoint = (device: Device) => {
	const [isDevice, setIsDevice] = useState(false);

	useEffect(() => {
		const mediaWatcher = window.matchMedia(MediaQueries[device]);
		setIsDevice(mediaWatcher.matches);

		const updateIsNarrowScreen = (e: MediaQueryListEvent) => {
			setIsDevice(e.matches);
		};

		if (mediaWatcher.addEventListener) {
			mediaWatcher.addEventListener('change', updateIsNarrowScreen);
			return () => {
				mediaWatcher.removeEventListener('change', updateIsNarrowScreen);
			};
		} else {
			mediaWatcher.addListener(updateIsNarrowScreen);
			return () => {
				mediaWatcher.removeListener(updateIsNarrowScreen);
			};
		}
	}, [device]);

	return isDevice;
};
