import { AxiosError } from 'axios';
import { createStore } from 'effector';
import { PageLayout } from 'shared/api';
import { Status } from 'shared/lib/effector/createStatus';

const $status = createStore<Status>(Status.Pending);

const $error = createStore<AxiosError['response'] | null>(null);

const $layout = createStore<PageLayout | null>(null);

export const pageModel = {
	status: $status,
	error: $error,
	layout: $layout,
};
