import React from 'react';
import { Page } from 'shared/api';
import { DefaultStyledProps } from 'shared/types';
import { ContactCard, Typography } from 'shared/ui';

export const LinksListWithDelimiter = ({
	column_width,
	title,
	paragraphs_list,
	links_list,
	className,
}: Page.Block.Link.ListWithDelimiter & DefaultStyledProps) => {
	return (
		<ContactCard className={column_width === 100 ? className : undefined} description={title}>
			{paragraphs_list?.map((paragraph, index) => (
				<Typography key={index} variant="large">
					{paragraph.text}
				</Typography>
			))}

			{links_list?.map(({ href, title, link_type }, index) => {
				const linkProps = link_type === 'base' ? { href } : { to: href };

				return (
					<ContactCard.Link key={index} linkType={link_type} {...linkProps}>
						{title}
					</ContactCard.Link>
				);
			})}
		</ContactCard>
	);
};
