import { Tabs as AntdTabs, TabsProps as AntdTabsProps } from 'antd';
import clsx from 'clsx';
import React from 'react';

import { Button } from '../button';
import styles from './style.module.scss';

export interface TabOption {
	title: React.ReactNode;
	onClick?: () => void;
	content?: React.ReactNode;
}

export interface TabsProps extends AntdTabsProps {
	options: TabOption[];
	tabContentClassName?: string;
}

export const Tabs = ({ className, tabContentClassName, options, children, ...rest }: TabsProps) => {
	const items = options.map(({ title, content, onClick }, key) => {
		return {
			key: `${key}`,
			label: (
				<Button className={styles.Tabs__Button} onClick={onClick} fullWidth>
					{title}
				</Button>
			),
			children: <div className={tabContentClassName}>{content}</div>,
		};
	});

	return <AntdTabs {...rest} centered className={clsx(styles.Tabs, className)} items={items} />;
};
