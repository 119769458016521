import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormProps } from 'features/forms/form';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Asserts } from 'yup';

import { schema } from './schema';
import { getFieldComponent, prepareDataForSending } from './utils';

type FormFields = Asserts<typeof schema>;

type SuppliersFormProps = Omit<FormProps, 'getFieldComponent'>;

const { BaseForm } = Form;

export const SuppliersForm = ({ form, className }: SuppliersFormProps) => {
	const formMethods = useForm<FormFields>({
		resolver: yupResolver(schema),
	});

	return (
		<FormProvider {...formMethods}>
			<BaseForm
				withFile
				form={form}
				className={className}
				getFieldComponent={(field, control, validationServerErrors, resetServerError) =>
					getFieldComponent(field, control, validationServerErrors, form.fields, resetServerError)
				}
				prepareDataForSending={prepareDataForSending}
			/>
		</FormProvider>
	);
};
