import React, { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { paths } from 'shared/lib/paths';

const MainPage = React.lazy(() => import('pages/main'));

export const mainPage: RouteObject = {
	path: paths.main,
	element: (
		<Suspense fallback={null}>
			<MainPage />
		</Suspense>
	),
};
