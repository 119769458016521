import { AxiosError } from 'axios';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { Search, searchApi } from 'shared/api';
import { Status } from 'shared/api/common';
import { createError, createStatus } from 'shared/lib/effector';
import { schemes } from 'shared/lib/schemes';

const {
	messages: { unknownError },
} = schemes;

const searchFx = createEffect(searchApi.get);

export const search = createEvent<string>();

export const $results = createStore<Search.Response | null>(null);

export const $status = createStatus(searchFx);

const $error = createError(searchFx);
export const $errorText = createStore<string>('');
export const setError = createEvent<string>();

export const clear = createEvent();
$results.on(clear, () => null);
$errorText.on(clear, () => '').on(setError, (_, error) => error);

sample({
	clock: search,
	target: searchFx,
});

sample({
	clock: searchFx.doneData,
	filter: (clock) => clock.data.status === Status.Success,
	fn: (clock) => clock.data,
	target: $results,
});

sample({
	clock: $error,
	filter: (error): error is AxiosError['response'] | null => error !== null,
	fn: (error) => {
		const data = error?.data as Search.Response;
		const messages = data?.details?.q;

		return messages ? `${messages.join('\n')}\n` : unknownError;
	},
	target: $errorText,
});
