import React from 'react';
import { BlockTypes, Page } from 'shared/api';
import { Typography, TypographyProps } from 'shared/ui';

import styles from '../styles.module.scss';

const getHeadingProps = (
	type: BlockTypes,
	id?: string,
	lower?: boolean
): { as: TypographyProps['as']; variant: TypographyProps['variant']; id?: string } | undefined => {
	switch (type) {
		case BlockTypes.Heading_1:
			return lower
				? { as: 'h1', variant: 'heading_2', id }
				: { as: 'h2', variant: 'heading_1', id };
		case BlockTypes.Heading_2:
			return lower
				? { as: 'h2', variant: 'heading_3', id }
				: { as: 'h3', variant: 'heading_2', id };
		case BlockTypes.Heading_3:
			return lower
				? { as: 'h3', variant: 'heading_4', id }
				: { as: 'h4', variant: 'heading_3', id };
		case BlockTypes.Heading_4:
			return lower
				? { as: 'h4', variant: 'heading_4', id }
				: { as: 'h5', variant: 'heading_4', id };
		case BlockTypes.Heading_5:
			return lower
				? { as: 'h5', variant: 'heading_4', id }
				: { as: 'h5', variant: 'heading_4', id };
	}
};

interface HeadingProps extends Page.Block.Base.Heading {
	id?: string;
	type: BlockTypes;
	lower?: boolean;
}

export const Heading = ({ text, id, type, lower = false }: HeadingProps) => {
	return (
		<Typography
			className={styles[`Title_${type}`]}
			{...getHeadingProps(type, id, lower)}
			html={text}
		/>
	);
};
