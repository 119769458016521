import { Input } from 'antd';
import { TextAreaProps as AndTextAreaProps } from 'antd/lib/input';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

import styles from './styles.module.scss';

const { TextArea: AntdTextarea } = Input;

export type TextAreaProps = AndTextAreaProps;

export const TextArea = forwardRef<TextAreaRef, TextAreaProps>(({ className, ...props }, ref) => {
	return (
		<AntdTextarea
			ref={ref}
			className={clsx(styles.TextArea, className)}
			autoSize
			autoComplete="off"
			{...props}
		/>
	);
});

TextArea.displayName = 'TextArea';
