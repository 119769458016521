import { createEvent, createStore } from 'effector';
import { useEffect } from 'react';

import { useBreakpoint } from './useBreakpoint';

const $isBlockedBody = createStore<boolean>(false);

const setBlockedBody = createEvent<boolean>();

$isBlockedBody.on(setBlockedBody, (_, state) => state);

export const blockedBodyModel = {
	isBlockedBody: $isBlockedBody,
	setBlockedBody,
};

export const useBlockedBody = (blocked: boolean, shadow: boolean) => {
	const isDesktop = useBreakpoint('desktop');

	useEffect(() => {
		document.body.style.overflow = blocked ? 'hidden' : '';
		document.body.style.paddingRight = blocked && isDesktop ? '15px' : '0px';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blocked]);

	useEffect(() => {
		const blackout = document.querySelector('.blackout') as HTMLElement;

		if (!blackout) {
			return;
		}

		blackout.style.height = shadow ? '100%' : '0';
		blackout.style.opacity = shadow ? '0.6' : '0';
		blackout.style.transition = shadow
			? 'height 0s 0s, opacity 0.5s 0s'
			: 'height 0s 0.3s, opacity 0.3s 0s';
	}, [shadow]);
};
