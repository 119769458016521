import { schemes } from 'shared/lib/schemes';
import { array, bool, mixed, object } from 'yup';

import { ExperienceVarinats } from './work-experience/types';

const {
	common: { requiredString, stringWithoutSpaces, email },
	messages: { required },
} = schemes;

const INCORRECT_YEAR = 'Некорректный год';

const companies = object({})
	.nullable()
	.when('company_has_experience_in_the_tobacco_industry', {
		is: (has_experience: ExperienceVarinats) => has_experience === ExperienceVarinats.Yes,
		then: (schema) => {
			return object().shape({
				array: array().of(
					object({
						tobacco_company_name_with_which_company_worked: requiredString,
						period_of_time_during_which_company_worked_with_tobacco_company: requiredString,
					})
				),
			});
		},
	});

export const schema = object({
	company_name: requiredString,
	company_inn: requiredString,
	company_year_of_registration: requiredString
		.min(4, INCORRECT_YEAR)
		.test(
			'year',
			INCORRECT_YEAR,
			(value: string | undefined) => !!value && Number(value) <= new Date().getFullYear()
		),
	company_total_number_of_employees: requiredString,
	company_annual_turnover: requiredString,
	company_email: email,
	company_phone: requiredString,
	company_site: requiredString,
	company_type: requiredString,
	// company_solutions_and_products: requiredString,

	company_products_and_service: requiredString,
	company_portfolio_file: mixed(),

	company_has_experience_in_the_tobacco_industry: mixed<ExperienceVarinats>()
		.oneOf(Object.values(ExperienceVarinats))
		.required(required),

	companies,

	company_main_clients: stringWithoutSpaces,
	company_main_competitors: stringWithoutSpaces,
	company_additional_information: stringWithoutSpaces,
	business_rules_confirm: bool().required(required).oneOf([true], required),
	personal_agreements_confirm: bool().required(required).oneOf([true], required),
});
