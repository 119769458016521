import { FieldValues } from 'react-hook-form';
import { UseControllerProps } from 'react-hook-form/dist/types/controller';
import { Form } from 'shared/api';
import { FormItemProps } from 'shared/ui';

export type ResetServerErrorFunc = (key: string) => void;

export type FormFieldWrapper<
	ComponentProps extends object,
	T extends FieldValues
> = ComponentProps &
	FormItemProps &
	UseControllerProps<T> & { serverError?: string; resetServerError: ResetServerErrorFunc };

export const getServerError = (allErrors: Form.ValidationErrors | null, fieldKey: string) => {
	const error = allErrors?.[fieldKey];

	return error ? `${error.join('\n')}\n` : '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeEmptyFields = (data: any) =>
	Object.fromEntries(Object.entries(data).filter(([_, field]) => !!field));
