import { Image as AntdImage } from 'antd';
import clsx from 'clsx';
import { useUnit } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { download } from 'shared/lib/utils';

import { blockedBodyModel } from '../../lib/hooks';
import { Button } from '../button';
import { Icons } from '../icons';
import { Typography } from '../typography';
import { Image, ImageProps } from './image';
import { ImageOrientation } from './image/utils';
import styles from './styles.module.scss';

interface ImageGroupProps {
	items: ImageProps[];
}

const getClassName = (
	arr: { ind: number; variant: ImageOrientation | undefined }[],
	index: number
) => {
	const element = arr.find((item) => item.ind === index);

	if (!element) {
		return '';
	}

	return styles[`PreviewGroup__Item_${element.variant}`];
};

export const ImageGroup = ({ items }: ImageGroupProps) => {
	const [visible, setVisible] = useState(false);
	const [currentImage, setCurrentImage] = useState<number | undefined>(undefined);
	const [arr, setArr] = useState<{ ind: number; variant: ImageOrientation | undefined }[]>([]);

	const setBlockedBody = useUnit(blockedBodyModel.setBlockedBody);

	const getImageTitle = (imageIndex: number) => {
		setTimeout(() => setCurrentImage(imageIndex), 0);
		return '';
	};

	useEffect(() => {
		setBlockedBody(visible);
	}, [visible, setBlockedBody]);

	return (
		<div className={styles.PreviewGroup}>
			<AntdImage.PreviewGroup
				preview={{
					className: styles.ImagePreviewGroup,
					countRender: (current) => getImageTitle(current),
					onVisibleChange: (value: boolean) => setVisible(value),
				}}
			>
				{items.map((item, index) => (
					<Image
						key={index}
						src={item.src}
						caption={item.caption}
						className={clsx(styles.PreviewGroup__Item, getClassName(arr, index))}
						getImageOrientation={(value) => {
							setArr((prev) => [...prev, { ind: index, variant: value }]);
						}}
					/>
				))}
			</AntdImage.PreviewGroup>

			{visible && (
				<div className={styles.Preview__Footer}>
					<Typography className={styles.Preview__FooterCaption} as="p" variant="small">
						{currentImage && items[currentImage - 1].caption}
					</Typography>
					{currentImage && (
						<Button
							{...download(items[currentImage - 1].src, items[currentImage - 1].caption)}
							icon={<Icons.Download />}
							className={styles.Preview__FooterButton}
						/>
					)}
				</div>
			)}
		</div>
	);
};
