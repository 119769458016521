// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_ProductsAndService__Textarea__DzNIZ {\n  margin-bottom: 0.5rem; }\n", "",{"version":3,"sources":["webpack://./src/features/forms/suppliers-form/styles.module.scss"],"names":[],"mappings":"AACC;EACC,qBAAqB,EAAA","sourcesContent":[".ProductsAndService {\n\t&__Textarea {\n\t\tmargin-bottom: 0.5rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProductsAndService__Textarea": "styles_ProductsAndService__Textarea__DzNIZ"
};
export default ___CSS_LOADER_EXPORT___;
