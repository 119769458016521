import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { FormItem, RadioGroup, RadioGroupProps } from 'shared/ui';

import { FormFieldWrapper } from '../utils';

type Props<T extends FieldValues> = FormFieldWrapper<RadioGroupProps, T>;

export const FormRadioGroup = <T extends object>({
	control,
	name,
	defaultValue,
	label,
	className,
	serverError,
	resetServerError,
	...props
}: Props<T>) => {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field, fieldState: { error } }) => {
				const fieldPrepareType = field as RadioGroupProps;
				return (
					<FormItem className={className} label={label} error={error} serverError={serverError}>
						<RadioGroup
							{...props}
							{...fieldPrepareType}
							onChange={(event) => {
								serverError && resetServerError(name);
								fieldPrepareType.onChange?.(event);
							}}
						/>
					</FormItem>
				);
			}}
		/>
	);
};
