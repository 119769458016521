/* eslint-disable react/display-name */
import React from 'react';

interface IconProps {
	size?: number;
	color?: string;
}

const MainLogo = React.memo(({ color = 'currentColor' }: IconProps) => (
	<svg width="149" height="64" viewBox="0 0 149 64" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M120.978 45.6596C119.938 46.699 119.938 48.3722 120.978 49.4117C122.017 50.4511 123.69 50.4511 124.73 49.4117C125.769 48.3722 125.769 46.699 124.73 45.6596C123.69 44.6202 122.017 44.6456 120.978 45.6596ZM128.457 38.1809C127.417 39.2203 127.417 40.8935 128.457 41.9329C129.496 42.9724 131.169 42.9724 132.209 41.9329C133.248 40.8935 133.248 39.2203 132.209 38.1809C131.169 37.1668 129.496 37.1668 128.457 38.1809ZM124.73 19.4968C125.769 18.4574 125.769 16.7842 124.73 15.7448C123.69 14.7054 122.017 14.7054 120.978 15.7448C119.938 16.7842 119.938 18.4574 120.978 19.4968C122.017 20.5109 123.69 20.5109 124.73 19.4968ZM132.209 12.0181C133.248 10.9787 133.248 9.3055 132.209 8.26608C131.169 7.22667 129.496 7.22667 128.457 8.26608C127.417 9.3055 127.417 10.9787 128.457 12.0181C129.496 13.0322 131.169 13.0322 132.209 12.0181ZM124.73 4.5394C125.769 3.49999 125.769 1.82679 124.73 0.787373C123.69 -0.252041 122.017 -0.252041 120.978 0.787373C119.938 1.82679 119.938 3.49999 120.978 4.5394C122.017 5.55346 123.69 5.55346 124.73 4.5394ZM147.166 23.2235L139.687 15.7448C138.648 14.7054 136.975 14.7054 135.935 15.7448C134.896 16.7842 134.896 18.4574 135.935 19.4968L141.538 25.0995L135.935 30.7022C134.896 31.7416 134.896 33.4148 135.935 34.4542C136.975 35.4937 138.648 35.4937 139.687 34.4542L147.166 26.9755C148.205 25.9361 148.205 24.2629 147.166 23.2235ZM128.457 23.2235L120.978 30.7022C119.938 31.7416 119.938 33.4148 120.978 34.4542C122.017 35.4937 123.69 35.4937 124.73 34.4542L132.209 26.9755C133.248 25.9361 133.248 24.2629 132.209 23.2235C131.169 22.1841 129.496 22.1841 128.457 23.2235Z"
			fill={color}
		/>
		<path
			d="M5.45566 38.0287C5.45566 39.423 4.31484 40.5639 2.79375 40.5639C1.27266 40.5639 0.131836 39.423 0.131836 38.0287V12.2969C0.131836 10.9025 1.27266 9.76172 2.79375 9.76172C4.31484 9.76172 5.45566 10.9025 5.45566 12.2969V38.0287Z"
			fill={color}
		/>
		<path
			d="M25.7109 34.8346C26.9024 34.8346 27.9672 35.6458 27.9672 37.0401C27.9672 37.8514 27.5615 38.5612 26.5728 39.1697C24.8489 40.2344 22.694 40.6401 20.1842 40.6401C13.4407 40.6401 10.7788 36.381 10.7788 28.6995V4.31128C10.7788 2.91694 11.9196 1.77612 13.4407 1.77612C14.9618 1.77612 16.1026 2.91694 16.1026 4.31128V10.1168H24.5701C25.9644 10.1168 27.1052 11.2576 27.1052 12.5759C27.1052 13.9702 25.9644 15.111 24.5701 15.111H16.1026V28.1671C16.1026 32.9078 17.6237 35.6458 21.4265 35.6458C23.1757 35.6204 24.7222 34.8346 25.7109 34.8346Z"
			fill={color}
		/>
		<path
			d="M47.7661 9.55933C52.4308 9.55933 55.7011 11.6382 56.842 14.7564C58.9715 11.6889 62.8503 9.55933 67.3629 9.55933C72.8895 9.55933 77.0218 12.2212 77.0218 20.4351V38.0291C77.0218 39.4235 75.881 40.5643 74.3599 40.5643C72.8388 40.5643 71.698 39.4235 71.698 38.0291V21.9055C71.698 16.962 69.5684 14.5789 65.2333 14.5789C61.6334 14.5789 58.7687 16.4549 57.3743 18.914C57.4504 19.4464 57.4504 20.3844 57.4504 21.0436V38.0291C57.4504 39.4235 56.3096 40.5643 54.7885 40.5643C53.2674 40.5643 52.1266 39.4235 52.1266 38.0291V20.7647C52.1266 17.0887 49.921 14.5536 45.738 14.5536C42.2648 14.5536 39.4001 16.4296 37.879 18.6098V38.0291C37.879 39.4235 36.7382 40.5643 35.2171 40.5643C33.696 40.5643 32.5552 39.4235 32.5552 38.0291V12.2973C32.5552 10.903 33.696 9.76214 35.2171 9.76214C36.7382 9.76214 37.879 10.903 37.879 12.2973V13.8944C40.0085 11.3086 43.8873 9.55933 47.7661 9.55933Z"
			fill={color}
		/>
		<path
			d="M82.879 34.2261C83.4114 33.567 84.0705 33.2881 84.755 33.2881C85.2874 33.2881 85.8198 33.4149 86.2761 33.7445C88.5324 35.2656 90.814 36.0768 93.1971 36.0768C95.9857 36.0768 98.5209 34.6825 98.5209 31.9445C98.5209 25.8855 82.3213 28.877 82.3213 17.8744C82.3213 12.6013 86.5803 9.40698 92.5126 9.40698C96.3153 9.40698 98.8505 10.3957 101.056 11.6633C101.715 12.0689 102.121 12.728 102.121 13.5393C102.121 13.9449 101.994 14.4012 101.715 14.8069C101.259 15.5421 100.524 15.8716 99.7124 15.8716C99.2561 15.8716 98.8505 15.7956 98.3688 15.5421C96.6956 14.6801 95.0477 14.0717 92.7661 14.0717C89.7746 14.0717 87.9747 15.7956 87.9747 17.7983C87.9747 24.1362 104.174 21.1194 104.174 31.6656C104.174 38.0035 98.5716 40.7415 92.5633 40.7415C88.5577 40.7415 85.7691 39.55 83.1579 37.5472C82.6255 37.1416 82.2959 36.4064 82.2959 35.6712C82.3466 35.1642 82.5494 34.6318 82.879 34.2261Z"
			fill={color}
		/>
		<path
			d="M2.79375 7.09995C4.26388 7.09995 5.45566 5.90817 5.45566 4.43804C5.45566 2.9679 4.26388 1.77612 2.79375 1.77612C1.32362 1.77612 0.131836 2.9679 0.131836 4.43804C0.131836 5.90817 1.32362 7.09995 2.79375 7.09995Z"
			fill={color}
		/>
		<path
			d="M1.67239 61.6308L1.01324 63.2533C0.937189 63.4815 0.78508 63.5576 0.582268 63.5576C0.303401 63.5576 0.100586 63.3294 0.100586 63.1012C0.100586 63.0505 0.125941 62.9745 0.151292 62.8984L2.63574 57.1183C2.73715 56.9155 2.88926 56.7126 3.19348 56.7126C3.4977 56.7126 3.67516 56.9155 3.75121 57.1183L6.23566 62.8731C6.26102 62.9238 6.28637 62.9998 6.28637 63.0759C6.28637 63.3294 6.08355 63.5576 5.80469 63.5576C5.55117 63.5576 5.39906 63.4055 5.32301 63.228L4.66387 61.6308H1.67239ZM2.07801 60.7182H4.28359L3.19348 58.0816L2.07801 60.7182Z"
			fill={color}
		/>
		<path
			d="M8.94709 59.9321C8.2626 59.9321 7.60346 60.4899 7.60346 61.3011C7.60346 62.1631 8.23724 62.6955 8.99779 62.6955C9.30201 62.6955 9.58088 62.6194 9.80904 62.4673C9.9358 62.3913 10.0372 62.3405 10.164 62.3405C10.3921 62.3405 10.5696 62.518 10.5696 62.7462C10.5696 62.8729 10.5189 62.9997 10.3921 63.1011C9.9358 63.4814 9.45412 63.5828 8.94709 63.5828C7.55275 63.5828 6.61475 62.6955 6.61475 61.3265C6.61475 60.0589 7.5274 59.0702 8.82033 59.0702C9.35271 59.0702 9.75834 59.1462 10.1133 59.3491C10.2654 59.4251 10.3921 59.5772 10.3921 59.7547C10.3921 59.9828 10.24 60.1857 9.9865 60.1857C9.91044 60.1857 9.80904 60.1603 9.70763 60.1096C9.47947 59.9828 9.25131 59.9321 8.94709 59.9321Z"
			fill={color}
		/>
		<path
			d="M13.283 59.9321C12.5985 59.9321 11.9394 60.4899 11.9394 61.3011C11.9394 62.1631 12.5732 62.6955 13.3337 62.6955C13.638 62.6955 13.9168 62.6194 14.145 62.4673C14.2717 62.3913 14.3731 62.3405 14.4999 62.3405C14.7281 62.3405 14.9055 62.518 14.9055 62.7462C14.9055 62.8729 14.8548 62.9997 14.7281 63.1011C14.2717 63.4814 13.7901 63.5828 13.283 63.5828C11.8887 63.5828 10.9507 62.6955 10.9507 61.3265C10.9507 60.0589 11.8633 59.0702 13.1563 59.0702C13.6887 59.0702 14.0943 59.1462 14.4492 59.3491C14.6013 59.4251 14.7281 59.5772 14.7281 59.7547C14.7281 59.9828 14.576 60.1857 14.3224 60.1857C14.2464 60.1857 14.145 60.1603 14.0436 60.1096C13.8154 59.9828 13.5872 59.9321 13.283 59.9321Z"
			fill={color}
		/>
		<path
			d="M17.694 62.7715C18.0743 62.7715 18.3532 62.6955 18.6067 62.5687C18.6828 62.518 18.7588 62.4927 18.8349 62.4927C19.0377 62.4927 19.1898 62.6194 19.1898 62.8476C19.1898 62.9743 19.1391 63.1011 19.0377 63.1518C18.5814 63.456 18.0997 63.5828 17.5926 63.5828C16.1983 63.5828 15.2856 62.6955 15.2856 61.3265C15.2856 60.0589 16.1223 59.0702 17.5419 59.0702C18.6574 59.0702 19.5701 59.8561 19.5701 61.0476C19.5701 61.3772 19.4687 61.6053 19.1391 61.6053H16.2997C16.3758 62.3405 16.9335 62.7715 17.694 62.7715ZM17.5166 59.8814C16.7814 59.8814 16.3758 60.3124 16.249 60.9209H18.6067C18.6067 60.3124 18.2011 59.8814 17.5166 59.8814Z"
			fill={color}
		/>
		<path
			d="M21.5464 61.9104C21.5464 62.4428 21.6985 62.6709 22.0027 62.6709C22.1295 62.6709 22.2309 62.6456 22.307 62.6456C22.5098 62.6456 22.7126 62.7216 22.7126 63.0005C22.7126 63.4315 22.2563 63.5329 21.7239 63.5329C21.014 63.5329 20.6084 63.1526 20.6084 62.2906V57.195C20.6084 56.9415 20.8112 56.7386 21.0901 56.7386C21.3436 56.7386 21.5718 56.9415 21.5718 57.195V61.9104H21.5464Z"
			fill={color}
		/>
		<path
			d="M25.5534 62.7715C25.9337 62.7715 26.2126 62.6955 26.4661 62.5687C26.5421 62.518 26.6182 62.4927 26.6942 62.4927C26.897 62.4927 27.0492 62.6194 27.0492 62.8476C27.0492 62.9743 26.9985 63.1011 26.8971 63.1518C26.4407 63.456 25.959 63.5828 25.452 63.5828C24.0577 63.5828 23.145 62.6955 23.145 61.3265C23.145 60.0589 23.9816 59.0702 25.4013 59.0702C26.5168 59.0702 27.4294 59.8561 27.4294 61.0476C27.4294 61.3772 27.328 61.6053 26.9985 61.6053H24.1591C24.2351 62.3405 24.7929 62.7715 25.5534 62.7715ZM25.3506 59.8814C24.6154 59.8814 24.2098 60.3124 24.083 60.9209H26.4407C26.4407 60.3124 26.0605 59.8814 25.3506 59.8814Z"
			fill={color}
		/>
		<path
			d="M29.3823 59.5265V59.6279C29.6105 59.3237 29.8894 59.0702 30.4218 59.0702C30.726 59.0702 31.0555 59.1716 31.0555 59.5265C31.0555 59.78 30.8781 59.9828 30.4978 59.9828C30.4471 59.9828 30.2189 59.9575 30.1682 59.9575C29.7373 59.9575 29.5344 60.211 29.4077 60.4899V63.0757C29.4077 63.3292 29.2049 63.5321 28.926 63.5321C28.6725 63.5321 28.4443 63.3292 28.4443 63.0757V59.5772C28.4443 59.273 28.6471 59.0702 28.926 59.0702C29.1795 59.0955 29.3823 59.2984 29.3823 59.5265Z"
			fill={color}
		/>
		<path
			d="M31.46 62.2144C31.46 61.3017 32.1444 60.8454 33.0571 60.8454C33.412 60.8454 33.843 60.9468 34.0965 61.1242V60.9975C34.0965 60.313 33.8937 59.9074 33.1839 59.9074C32.7529 59.9074 32.4233 60.0595 32.2459 60.0595C31.9923 60.0595 31.8656 59.882 31.8656 59.7045C31.8656 59.5524 31.967 59.4003 32.1444 59.3496C32.4994 59.1975 32.905 59.1215 33.2853 59.1215C34.5528 59.1215 34.9838 59.7552 34.9838 60.8961V63.1777C34.9838 63.3805 34.8064 63.5833 34.5528 63.5833C34.3247 63.5833 34.1472 63.4059 34.1472 63.1777V63.0256C33.8937 63.3298 33.4627 63.5833 32.8036 63.5833C32.0177 63.5326 31.46 63.051 31.46 62.2144ZM34.0965 61.758C33.8684 61.6059 33.5895 61.5045 33.2346 61.5045C32.8036 61.5045 32.4233 61.7073 32.4233 62.1383C32.4233 62.4932 32.7022 62.7214 33.1078 62.7214C33.6148 62.7214 33.8937 62.4679 34.0965 62.2397V61.758Z"
			fill={color}
		/>
		<path
			d="M38.6089 63.0004C38.6089 63.1525 38.5329 63.2793 38.3554 63.3807C38.0765 63.5328 37.7977 63.5835 37.4428 63.5835C36.4794 63.5835 36.0991 62.9497 36.0991 61.8596V58.4372C36.0991 58.1836 36.3019 57.9808 36.5808 57.9808C36.8343 57.9808 37.0625 58.1836 37.0625 58.4372V59.1977H38.1019C38.3554 59.1977 38.5329 59.4005 38.5329 59.6287C38.5329 59.8568 38.3301 60.0597 38.1019 60.0597H37.0118V61.7836C37.0118 62.3667 37.1892 62.6962 37.6456 62.6962C37.8737 62.6962 38.0765 62.6202 38.2033 62.6202C38.4568 62.6202 38.6089 62.7723 38.6089 63.0004Z"
			fill={color}
		/>
		<path
			d="M41.4489 62.7715C41.8292 62.7715 42.1081 62.6955 42.3616 62.5687C42.4376 62.518 42.5137 62.4927 42.5897 62.4927C42.7926 62.4927 42.9447 62.6194 42.9447 62.8476C42.9447 62.9743 42.894 63.1011 42.7926 63.1518C42.3362 63.456 41.8546 63.5828 41.3475 63.5828C39.9532 63.5828 39.0405 62.6955 39.0405 61.3265C39.0405 60.0589 39.8771 59.0702 41.2968 59.0702C42.4123 59.0702 43.3249 59.8561 43.3249 61.0476C43.3249 61.3772 43.2235 61.6053 42.894 61.6053H40.0546C40.1306 62.3405 40.6884 62.7715 41.4489 62.7715ZM41.2715 59.8814C40.5363 59.8814 40.1306 60.3124 40.0039 60.9209H42.3616C42.3616 60.3124 41.956 59.8814 41.2715 59.8814Z"
			fill={color}
		/>
		<path
			d="M46.1646 62.2144C46.1646 61.3017 46.849 60.8454 47.7617 60.8454C48.1166 60.8454 48.5476 60.9468 48.8011 61.1242V60.9975C48.8011 60.313 48.5983 59.9074 47.8885 59.9074C47.4575 59.9074 47.1279 60.0595 46.9505 60.0595C46.6969 60.0595 46.5702 59.882 46.5702 59.7045C46.5702 59.5524 46.6716 59.4003 46.849 59.3496C47.204 59.1975 47.6096 59.1215 47.9899 59.1215C49.2574 59.1215 49.6884 59.7552 49.6884 60.8961V63.1777C49.6884 63.3805 49.511 63.5833 49.2574 63.5833C49.0293 63.5833 48.8518 63.4059 48.8518 63.1777V63.0256C48.5983 63.3298 48.1673 63.5833 47.5082 63.5833C46.6969 63.5326 46.1646 63.051 46.1646 62.2144ZM48.7758 61.758C48.5476 61.6059 48.2687 61.5045 47.9138 61.5045C47.4828 61.5045 47.1026 61.7073 47.1026 62.1383C47.1026 62.4932 47.3814 62.7214 47.787 62.7214C48.2941 62.7214 48.5729 62.4679 48.7758 62.2397V61.758Z"
			fill={color}
		/>
		<path
			d="M55.1397 59.0711C56.4326 59.0711 57.1931 59.9837 57.1931 61.2513C57.1931 62.671 56.2551 63.5836 54.7594 63.5836C54.227 63.5836 53.6946 63.4569 53.289 63.2794C53.0862 63.2034 52.9341 63.0006 52.9341 62.7724V57.2458C52.9341 56.9922 53.1369 56.7894 53.4158 56.7894C53.6693 56.7894 53.8974 56.9922 53.8974 57.2458V59.5274C54.2017 59.2739 54.658 59.0711 55.1397 59.0711ZM53.8974 62.4428C54.1763 62.6456 54.4552 62.7217 54.8101 62.7217C55.596 62.7217 56.2044 62.2147 56.2044 61.3274C56.2044 60.4401 55.6467 59.9584 54.9369 59.9584C54.5059 59.9584 54.1003 60.1865 53.9228 60.4401V62.4428H53.8974Z"
			fill={color}
		/>
		<path
			d="M60.3605 62.7715C60.7408 62.7715 61.0197 62.6955 61.2732 62.5687C61.3493 62.518 61.4253 62.4927 61.5014 62.4927C61.7042 62.4927 61.8563 62.6194 61.8563 62.8476C61.8563 62.9743 61.8056 63.1011 61.7042 63.1518C61.2478 63.456 60.7662 63.5828 60.2591 63.5828C58.8648 63.5828 57.9521 62.6955 57.9521 61.3265C57.9521 60.0589 58.7887 59.0702 60.2084 59.0702C61.3239 59.0702 62.2366 59.8561 62.2366 61.0476C62.2366 61.3772 62.1351 61.6053 61.8056 61.6053H58.9662C59.0423 62.3405 59.5746 62.7715 60.3605 62.7715ZM60.1577 59.8814C59.4225 59.8814 59.0169 60.3124 58.8902 60.9209H61.2479C61.2479 60.3124 60.8422 59.8814 60.1577 59.8814Z"
			fill={color}
		/>
		<path
			d="M65.6587 63.0004C65.6587 63.1525 65.5827 63.2793 65.4052 63.3807C65.1263 63.5328 64.8475 63.5835 64.4926 63.5835C63.5292 63.5835 63.1489 62.9497 63.1489 61.8596V58.4372C63.1489 58.1836 63.3517 57.9808 63.6306 57.9808C63.8841 57.9808 64.1123 58.1836 64.1123 58.4372V59.1977H65.1517C65.4052 59.1977 65.5827 59.4005 65.5827 59.6287C65.5827 59.8568 65.3799 60.0597 65.1517 60.0597H64.1123V61.7836C64.1123 62.3667 64.2897 62.6962 64.7461 62.6962C64.9742 62.6962 65.1771 62.6202 65.3038 62.6202C65.532 62.6202 65.6587 62.7723 65.6587 63.0004Z"
			fill={color}
		/>
		<path
			d="M68.9307 63.0004C68.9307 63.1525 68.8546 63.2793 68.6772 63.3807C68.3983 63.5328 68.1194 63.5835 67.7645 63.5835C66.8012 63.5835 66.4209 62.9497 66.4209 61.8596V58.4372C66.4209 58.1836 66.6237 57.9808 66.9026 57.9808C67.1561 57.9808 67.3843 58.1836 67.3843 58.4372V59.1977H68.4237C68.6772 59.1977 68.8546 59.4005 68.8546 59.6287C68.8546 59.8568 68.6518 60.0597 68.4237 60.0597H67.3843V61.7836C67.3843 62.3667 67.5617 62.6962 68.018 62.6962C68.2462 62.6962 68.449 62.6202 68.5758 62.6202C68.7786 62.6202 68.9307 62.7723 68.9307 63.0004Z"
			fill={color}
		/>
		<path
			d="M71.7683 62.7715C72.1485 62.7715 72.4274 62.6955 72.6809 62.5687C72.757 62.518 72.833 62.4927 72.9091 62.4927C73.1119 62.4927 73.264 62.6194 73.264 62.8476C73.264 62.9743 73.2133 63.1011 73.1119 63.1518C72.6556 63.456 72.1739 63.5828 71.6669 63.5828C70.2725 63.5828 69.3599 62.6955 69.3599 61.3265C69.3599 60.0589 70.1965 59.0702 71.6161 59.0702C72.7316 59.0702 73.6443 59.8561 73.6443 61.0476C73.6443 61.3772 73.5429 61.6053 73.2133 61.6053H70.3739C70.45 62.3405 71.0077 62.7715 71.7683 62.7715ZM71.5908 59.8814C70.8556 59.8814 70.45 60.3124 70.3232 60.9209H72.6809C72.6809 60.3124 72.2753 59.8814 71.5908 59.8814Z"
			fill={color}
		/>
		<path
			d="M75.5987 59.5265V59.6279C75.8268 59.3237 76.1057 59.0702 76.6381 59.0702C76.9423 59.0702 77.2719 59.1716 77.2719 59.5265C77.2719 59.78 77.0944 59.9828 76.7141 59.9828C76.6634 59.9828 76.4353 59.9575 76.3845 59.9575C75.9536 59.9575 75.7508 60.211 75.624 60.4899V63.0757C75.624 63.3292 75.4212 63.5321 75.1423 63.5321C74.8888 63.5321 74.6606 63.3292 74.6606 63.0757V59.5772C74.6606 59.273 74.8635 59.0702 75.1423 59.0702C75.4212 59.0955 75.5987 59.2984 75.5987 59.5265Z"
			fill={color}
		/>
		<path
			d="M81.0723 63.1012C81.0723 63.3547 80.8695 63.5575 80.5906 63.5575C80.3118 63.5575 80.1343 63.3547 80.1343 63.1012V58.4618C80.1343 57.2703 80.692 56.6619 81.6554 56.6619C82.0103 56.6619 82.3145 56.7126 82.568 56.8647C82.6948 56.9407 82.7708 57.0421 82.7708 57.2196C82.7708 57.4731 82.5934 57.6252 82.3399 57.6252C82.1878 57.6252 82.0103 57.5492 81.8328 57.5492C81.3765 57.5492 81.0976 57.8787 81.0976 58.5632V59.1717H81.9596C82.2131 59.1717 82.4159 59.3745 82.4159 59.6026C82.4159 59.8308 82.2131 60.0336 81.9596 60.0336H81.0976V63.1012H81.0723Z"
			fill={color}
		/>
		<path
			d="M86.1941 59.5519C86.1941 59.2984 86.3969 59.0956 86.6504 59.0956C86.9039 59.0956 87.1321 59.2984 87.1321 59.5519V63.1011C87.1321 63.3547 86.9293 63.5575 86.6504 63.5575C86.3715 63.5575 86.1941 63.3547 86.1941 63.1011V62.8983C85.9152 63.2532 85.4082 63.5575 84.7237 63.5575C83.811 63.5575 83.1772 62.9997 83.1772 61.7575V59.5519C83.1772 59.2984 83.3801 59.0956 83.6589 59.0956C83.9124 59.0956 84.1406 59.2984 84.1406 59.5519V61.5547C84.1406 62.2645 84.4195 62.6955 85.104 62.6955C85.5349 62.6955 85.9406 62.442 86.2194 62.1124V59.5519H86.1941Z"
			fill={color}
		/>
		<path
			d="M90.8589 63.0004C90.8589 63.1525 90.7829 63.2793 90.6054 63.3807C90.3265 63.5328 90.0477 63.5835 89.6928 63.5835C88.7294 63.5835 88.3491 62.9497 88.3491 61.8596V58.4372C88.3491 58.1836 88.5519 57.9808 88.8308 57.9808C89.0843 57.9808 89.3125 58.1836 89.3125 58.4372V59.1977H90.3519C90.6054 59.1977 90.7829 59.4005 90.7829 59.6287C90.7829 59.8568 90.5801 60.0597 90.3519 60.0597H89.3125V61.7836C89.3125 62.3667 89.4899 62.6962 89.9463 62.6962C90.1744 62.6962 90.3772 62.6202 90.504 62.6202C90.7068 62.6202 90.8589 62.7723 90.8589 63.0004Z"
			fill={color}
		/>
		<path
			d="M94.6628 59.5519C94.6628 59.2984 94.8656 59.0956 95.1192 59.0956C95.398 59.0956 95.6008 59.2984 95.6008 59.5519V63.1011C95.6008 63.3547 95.398 63.5575 95.1192 63.5575C94.8403 63.5575 94.6628 63.3547 94.6628 63.1011V62.8983C94.384 63.2532 93.8769 63.5575 93.1924 63.5575C92.2798 63.5575 91.646 62.9997 91.646 61.7575V59.5519C91.646 59.2984 91.8488 59.0956 92.1277 59.0956C92.3812 59.0956 92.6094 59.2984 92.6094 59.5519V61.5547C92.6094 62.2645 92.8882 62.6955 93.5727 62.6955C94.0037 62.6955 94.4093 62.442 94.6882 62.1124V59.5519H94.6628Z"
			fill={color}
		/>
		<path
			d="M97.8565 59.5265V59.6279C98.0846 59.3237 98.3635 59.0702 98.8959 59.0702C99.2001 59.0702 99.5297 59.1716 99.5297 59.5265C99.5297 59.78 99.3522 59.9828 98.9719 59.9828C98.9212 59.9828 98.6931 59.9575 98.6424 59.9575C98.2114 59.9575 98.0086 60.211 97.8818 60.4899V63.0757C97.8818 63.3292 97.679 63.5321 97.4001 63.5321C97.1466 63.5321 96.9185 63.3292 96.9185 63.0757V59.5772C96.9185 59.273 97.1213 59.0702 97.4001 59.0702C97.6537 59.0955 97.8565 59.2984 97.8565 59.5265Z"
			fill={color}
		/>
		<path
			d="M102.343 62.7716C102.723 62.7716 103.002 62.6956 103.256 62.5688C103.332 62.5181 103.408 62.4928 103.484 62.4928C103.687 62.4928 103.839 62.6195 103.839 62.8477C103.839 62.9745 103.788 63.1012 103.687 63.1519C103.23 63.4561 102.749 63.5829 102.242 63.5829C100.847 63.5829 99.9346 62.6956 99.9346 61.3266C99.9346 60.059 100.771 59.0703 102.191 59.0703C103.306 59.0703 104.219 59.8562 104.219 61.0477C104.219 61.3773 104.118 61.6055 103.788 61.6055H100.949C101.025 62.3407 101.582 62.7716 102.343 62.7716ZM102.14 59.8816C101.405 59.8816 100.999 60.3125 100.873 60.921H103.23C103.256 60.3125 102.85 59.8816 102.14 59.8816Z"
			fill={color}
		/>
	</svg>
));

const Arrow = React.memo(({ size = 16, color = 'currentColor' }: IconProps) => {
	if (size === 24) {
		return (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M2.75 16.75L11.5429 7.95711C11.9334 7.56658 12.5666 7.56658 12.9571 7.95711L21.25 16.25"
					stroke={color}
					strokeWidth="2"
					strokeLinecap="round"
				/>
			</svg>
		);
	}
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M2 10L8 4L14 10" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
});

const AngleArrow = React.memo(({ size = 20, color = 'currentColor' }: IconProps) => {
	if (size === 16) {
		return (
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M4 4H12M12 4V12M12 4L4 12"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		);
	}

	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1 1H19M19 1V19M19 1L1 19"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
});

const Quote = React.memo(({ color = 'currentColor' }: IconProps) => (
	<svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.7236 0.552787C11.893 0.214003 12.2393 0 12.618 0H18.382C19.1253 0 19.6088 0.782313 19.2764 1.44721L8.27639 23.4472C8.107 23.786 7.76074 24 7.38197 24H1.61803C0.874652 24 0.391156 23.2177 0.723607 22.5528L11.7236 0.552787Z"
			fill={color}
		/>
		<path
			d="M27.7236 0.552787C27.893 0.214003 28.2393 0 28.618 0H34.382C35.1253 0 35.6088 0.782313 35.2764 1.44721L24.2764 23.4472C24.107 23.786 23.7607 24 23.382 24H17.618C16.8747 24 16.3912 23.2177 16.7236 22.5528L27.7236 0.552787Z"
			fill={color}
		/>
	</svg>
));

const Archive = React.memo(({ size = 16, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.5 6V13C14.5 13.2761 14.2761 13.5 14 13.5H2C1.72386 13.5 1.5 13.2761 1.5 13V5V3C1.5 2.72386 1.72386 2.5 2 2.5H6.2457C6.46894 2.5 6.66513 2.64799 6.72646 2.86264L7.16916 4.41208C7.35315 5.05603 7.94172 5.5 8.61144 5.5H14C14.2761 5.5 14.5 5.72386 14.5 6Z"
			stroke={color}
		/>
	</svg>
));

const Home = React.memo(({ size = 16, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13 14.5H3C2.72386 14.5 2.5 14.2761 2.5 14V5.53518C2.5 5.36801 2.58355 5.21189 2.72265 5.11916L7.72265 1.78583C7.8906 1.67386 8.1094 1.67386 8.27735 1.78583L13.2774 5.11916C13.4164 5.21189 13.5 5.36801 13.5 5.53518V14C13.5 14.2761 13.2761 14.5 13 14.5Z"
			stroke={color}
		/>
		<rect x="6.5" y="8.5" width="3" height="6" rx="0.5" stroke="white" />
	</svg>
));

const Image = React.memo(({ size = 16, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.73727 11.1734L5.86273 6.12182C6.0628 5.87684 6.4372 5.87684 6.63727 6.12182L7.96273 7.74484C8.52442 8.43262 9.57558 8.43262 10.1373 7.74484C10.2988 7.54701 10.6012 7.54701 10.7627 7.74484L14.2627 12.0306L14.5 11.8368V13C14.5 13.2761 14.2761 13.5 14 13.5H2C1.72386 13.5 1.5 13.2761 1.5 13V10.9796L1.73727 11.1734ZM14.5 10.7401L11.5373 7.1123C10.9756 6.42452 9.92442 6.42452 9.36273 7.1123C9.20117 7.31013 8.89883 7.31013 8.73727 7.1123L7.4118 5.48928C6.8116 4.75435 5.6884 4.75435 5.0882 5.48928L1.5 9.883V3C1.5 2.72386 1.72386 2.5 2 2.5H14C14.2761 2.5 14.5 2.72386 14.5 3V10.7401Z"
			stroke={color}
		/>
	</svg>
));

const Download = React.memo(({ size = 16, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13 7L8 12M8 12L3 7M8 12V2"
			stroke={color}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M3 14H13" stroke={color} strokeLinecap="round" />
	</svg>
));

const ArrowDown = React.memo(({ size = 16, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13.6562 8L7.9994 13.6569M7.9994 13.6569L2.34254 8M7.9994 13.6569V2.34315"
			stroke={color}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
));

const Attention = React.memo(({ size = 16, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.396 10.266L7.18 1.5H8.926L8.71 10.266H7.396ZM8.062 12.21C8.362 12.21 8.614 12.312 8.818 12.516C9.034 12.72 9.142 12.978 9.142 13.29C9.142 13.602 9.034 13.86 8.818 14.064C8.614 14.268 8.362 14.37 8.062 14.37C7.75 14.37 7.492 14.268 7.288 14.064C7.096 13.86 7 13.602 7 13.29C7 12.978 7.096 12.72 7.288 12.516C7.492 12.312 7.75 12.21 8.062 12.21Z"
			fill={color}
		/>
	</svg>
));

const Accept = React.memo(({ size = 16, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M3 7.5L6.23641 10.7364C6.64831 11.1483 7.32368 11.1224 7.70277 10.6801L13 4.5"
			stroke={color}
			strokeLinecap="round"
		/>
	</svg>
));

const Post = React.memo(({ size = 16, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.69005 3.04896C1.28949 3.17942 1 3.5559 1 4V12C1 12.5523 1.44772 13 2 13H14C14.5523 13 15 12.5523 15 12V4C15 3.5559 14.7105 3.17942 14.3099 3.04896C14.235 3.02456 14.1562 3.00877 14.0747 3.00275C14.05 3.00093 14.0251 3 14 3H2C1.97487 3 1.94996 3.00093 1.92529 3.00275C1.84377 3.00877 1.76497 3.02456 1.69005 3.04896ZM2 4.28953V12H14V4.28953L8.87738 7.98373C8.35347 8.36155 7.64653 8.36155 7.12262 7.98373L2 4.28953ZM12.6919 4L8.29246 7.17264C8.11782 7.29858 7.88218 7.29858 7.70754 7.17264L3.30815 4H12.6919Z"
			fill={color}
		/>
	</svg>
));

const Attach = React.memo(({ size = 16, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.93964 8.35386C6.74438 8.54912 6.74438 8.8657 6.93964 9.06096C7.91595 10.0373 9.49887 10.0373 10.4752 9.06096L12.5965 6.93964C13.5728 5.96333 13.5728 4.38042 12.5965 3.40411C11.6202 2.4278 10.0373 2.4278 9.06096 3.40411L7.64675 4.81832C7.45149 5.01358 7.45149 5.33017 7.64675 5.52543C7.84201 5.72069 8.15859 5.72069 8.35386 5.52543L9.76807 4.11121C10.3539 3.52543 11.3036 3.52543 11.8894 4.11121C12.4752 4.697 12.4752 5.64675 11.8894 6.23254L9.76807 8.35386C9.18228 8.93964 8.23253 8.93964 7.64675 8.35386C7.45149 8.15859 7.1349 8.15859 6.93964 8.35386Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.06096 7.64675C9.25622 7.45149 9.25622 7.1349 9.06096 6.93964C8.08465 5.96333 6.50174 5.96333 5.52543 6.93964L3.40411 9.06096C2.4278 10.0373 2.4278 11.6202 3.40411 12.5965C4.38042 13.5728 5.96333 13.5728 6.93964 12.5965L8.35386 11.1823C8.54912 10.987 8.54912 10.6704 8.35386 10.4752C8.15859 10.2799 7.84201 10.2799 7.64675 10.4752L6.23254 11.8894C5.64675 12.4752 4.697 12.4752 4.11121 11.8894C3.52543 11.3036 3.52543 10.3539 4.11121 9.76807L6.23254 7.64675C6.81832 7.06096 7.76807 7.06096 8.35386 7.64675C8.54912 7.84201 8.8657 7.84201 9.06096 7.64675Z"
			fill={color}
		/>
	</svg>
));

const Trash = React.memo(({ size = 16, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect x="3.5" y="3.5" width="9" height="11" rx="0.5" stroke={color} />
		<rect x="2" y="3" width="12" height="1" rx="0.5" fill={color} />
		<rect x="5.5" y="1.5" width="5" height="2" rx="0.5" stroke={color} />
		<rect x="6" y="6" width="1" height="6" rx="0.5" fill={color} />
		<rect x="9" y="6" width="1" height="6" rx="0.5" fill={color} />
	</svg>
));

const File = React.memo(({ size = 24, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15 14L12 17M12 17L9 14M12 17V9"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M20 22H4V2L15.4577 2L20 6.31801V22Z" stroke={color} strokeWidth="2" />
	</svg>
));

const Search = React.memo(({ size = 24, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M20 20L16.2223 16.2156M18.3158 11.1579C18.3158 13.0563 17.5617 14.8769 16.2193 16.2193C14.8769 17.5617 13.0563 18.3158 11.1579 18.3158C9.2595 18.3158 7.43886 17.5617 6.0965 16.2193C4.75413 14.8769 4 13.0563 4 11.1579C4 9.2595 4.75413 7.43886 6.0965 6.0965C7.43886 4.75413 9.2595 4 11.1579 4C13.0563 4 14.8769 4.75413 16.2193 6.0965C17.5617 7.43886 18.3158 9.2595 18.3158 11.1579V11.1579Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
));

const Close = React.memo(({ size = 24, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="2.60156"
			y="1.29297"
			width="28"
			height="2"
			rx="1"
			transform="rotate(45 2.60156 1.29297)"
			fill={color}
		/>
		<rect
			x="1.60156"
			y="21.293"
			width="28"
			height="2"
			rx="1"
			transform="rotate(-45 1.60156 21.293)"
			fill={color}
		/>
	</svg>
));

const VK = React.memo(({ size = 24, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M23.9662 6.01241C23.9662 6.01241 23.7913 5.50138 22.7041 5.64192L19.1054 5.66747C18.8305 5.62914 18.6306 5.74412 18.6306 5.74412C18.6306 5.74412 18.4181 5.8591 18.3182 6.14017C17.7309 7.68604 16.9812 9.01471 16.9812 9.01471C15.3817 11.7998 14.732 11.9531 14.4695 11.7743C13.8573 11.3782 14.0197 10.1645 14.0197 9.29578C14.0197 6.58732 14.4196 5.46306 13.2325 5.16921C12.8451 5.07978 12.5577 5.0159 11.5456 5.00313C10.2585 4.99035 9.17142 5.00313 8.55914 5.30975C8.14678 5.51416 7.83439 5.97409 8.02183 5.99964C8.25924 6.03796 8.79655 6.15295 9.08395 6.54899C9.08395 6.54899 9.39634 7.0728 9.44632 8.22262C9.58377 11.4038 8.9465 11.7998 8.9465 11.7998C8.45917 12.0681 7.60947 11.621 6.35991 8.98916C6.35991 8.98916 5.63517 7.68604 5.07287 6.25515C4.9729 5.98686 4.77298 5.84633 4.77298 5.84633C4.77298 5.84633 4.54806 5.66747 4.22317 5.61636L0.799381 5.64192C0.274567 5.64192 0.0871334 5.88465 0.0871334 5.88465C0.0871334 5.88465 -0.1003 6.08907 0.0746379 6.51067C2.74869 12.9369 5.79761 16.1564 5.79761 16.1564C5.79761 16.1564 8.58413 19.1331 11.7455 18.9287H13.1825C13.6198 18.8904 13.8448 18.6476 13.8448 18.6476C13.8448 18.6476 14.0447 18.4177 14.0322 17.9961C14.0072 16.0286 14.9069 15.7348 14.9069 15.7348C15.7816 15.4537 16.9062 17.6383 18.1058 18.4815C18.1058 18.4815 19.0179 19.1203 19.7052 18.9798L22.9041 18.9287C24.591 18.9159 23.7912 17.485 23.7912 17.485C23.7288 17.3701 23.3289 16.4885 21.4046 14.6616C19.4053 12.758 19.6677 13.0646 22.0919 9.76848C23.5663 7.76269 24.1536 6.53622 23.9662 6.01241Z"
			fill={color}
		/>
	</svg>
));

const Telegram = React.memo(({ size = 24, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M20.7106 3.65451C20.7106 3.65451 22.6531 2.89701 22.4906 4.73651C22.4371 5.49401 21.9516 8.14551 21.5736 11.013L20.2786 19.508C20.2786 19.508 20.1706 20.7525 19.1991 20.969C18.2281 21.185 16.7711 20.2115 16.5011 19.995C16.2851 19.8325 12.4541 17.3975 11.1051 16.2075C10.7271 15.8825 10.2951 15.2335 11.1591 14.476L16.8246 9.06501C17.4721 8.41601 18.1196 6.90101 15.4216 8.74051L7.86665 13.8805C7.86665 13.8805 7.00315 14.422 5.38465 13.935L1.87665 12.8525C1.87665 12.8525 0.581647 12.041 2.79415 11.2295C8.19065 8.68651 14.8281 6.08951 20.7096 3.65451H20.7106Z"
			fill={color}
		/>
	</svg>
));

const Youtube = React.memo(({ size = 24, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M23.0194 6.50346C22.7549 5.51804 21.9755 4.74196 20.9859 4.47858C19.1923 4 12 4 12 4C12 4 4.80774 4 3.01405 4.47858C2.02449 4.742 1.24513 5.51804 0.980607 6.50346C0.5 8.28958 0.5 12.0162 0.5 12.0162C0.5 12.0162 0.5 15.7428 0.980607 17.5289C1.24513 18.5143 2.02449 19.258 3.01405 19.5214C4.80774 20 12 20 12 20C12 20 19.1923 20 20.9859 19.5214C21.9755 19.258 22.7549 18.5143 23.0194 17.5289C23.5 15.7428 23.5 12.0162 23.5 12.0162C23.5 12.0162 23.5 8.28958 23.0194 6.50346ZM9.64771 15.3996V8.63271L15.659 12.0163L9.64771 15.3996Z"
			fill={color}
		/>
	</svg>
));

const RedCollar = React.memo(({ color = 'currentColor' }: IconProps) => (
	<svg width="27" height="12" viewBox="0 0 27 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.5265 5.67647L25.5147 0.5L27 1.89367L17.9293 11.5L13.4735 7.36878L9.07073 11.5L0 1.89367L1.48526 0.5L13.5265 5.67647Z"
			fill={color}
		/>
	</svg>
));

const Loader = React.memo(({ size = 40, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M20 39C15.6042 39 11.3445 37.4758 7.94653 34.6872C4.54857 31.8986 2.22265 28.018 1.36508 23.7067C0.507511 19.3954 1.17135 14.9202 3.2435 11.0435C5.31564 7.16676 8.66787 4.12847 12.729 2.44629C16.7902 0.764108 21.3089 0.542115 25.5154 1.81813C29.7219 3.09415 33.3558 5.78923 35.7979 9.44417C38.2401 13.0991 39.3394 17.4877 38.9085 21.8623C38.4777 26.2369 36.5433 30.3268 33.435 33.435"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
));

const Plus = React.memo(({ size = 16, color = 'currentColor' }: IconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.0003 2.34296L8.0003 7.99981L13.6572 7.99981"
			stroke={color}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M2.34345 7.99981H8.0003V13.6567"
			stroke={color}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
));

export const Icons = {
	Search,
	VK,
	Telegram,
	Youtube,
	RedCollar,
	Arrow,
	AngleArrow,
	Close,
	Quote,
	Archive,
	Home,
	Image,
	File,
	Download,
	ArrowDown,
	Loader,
	Attention,
	Accept,
	Post,
	Attach,
	Trash,
	Plus,
	MainLogo,
};
