import { Form as AntdForm, FormItemProps as AntdFormItemProps } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { Typography } from 'shared/ui';

import styles from './styles.module.scss';

export interface FormItemProps extends AntdFormItemProps {
	fullWidth?: boolean;
	error?: FieldError;
	serverError?: string;
}

export const FormItem = ({
	label,
	error,
	serverError,
	children,
	className,
	fullWidth = true,
	...props
}: FormItemProps) => {
	const labelElement = label && <Typography variant="extra_small_bold">{label}</Typography>;

	const errorElement = (error?.message || serverError) && (
		<Typography className={styles.ErrorText} variant="extra_small" as="p">
			{error?.message || serverError}
		</Typography>
	);

	return (
		<AntdForm.Item
			className={clsx(styles.FormItem, fullWidth && styles.FormItem_FullWidth, className)}
			label={labelElement}
			{...props}
		>
			<>
				{children}
				{errorElement}
			</>
		</AntdForm.Item>
	);
};
