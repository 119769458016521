import clsx from 'clsx';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { DefaultStyledProps } from 'shared/types';

import { Button } from '../button';
import { Typography } from '../typography';
import styles from './styles.module.scss';

export type NewsCardVariant = 'main' | 'inner';

interface NewsCardProps extends DefaultStyledProps, LinkProps {
	title: string;
	date: string;
	variant?: NewsCardVariant;
}

export const NewsCard = ({
	className,
	variant = 'inner',
	title,
	date,
	...props
}: NewsCardProps) => {
	const titleVariant = variant === 'main' ? 'heading_3' : 'heading_4';

	return (
		<Link className={clsx(styles.NewsCard, styles[`NewsCard_${variant}`], className)} {...props}>
			<Typography className={styles.NewsCard__Date} variant="medium">
				{date}
			</Typography>
			<div className={styles.NewsCard__TitleContainer}>
				<Typography className={styles.NewsCard__Title} variant={titleVariant} as="h3">
					{title}
				</Typography>
			</div>
			<Button.Arrow className={styles.NewsCard__Button} />
		</Link>
	);
};
