import { Collapse } from 'antd';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { DefaultStyledProps } from 'shared/types';
import { Button, Typography } from 'shared/ui';

import styles from './styles.module.scss';

const { Panel } = Collapse;

export interface AccordionProps extends DefaultStyledProps {
	isOpen?: boolean;
	title: ReactNode;
	content?: ReactNode;
	headerClassName?: string;
	iconClassName?: string;
}

export const Accordion = ({
	isOpen = false,
	title,
	content,
	className,
	headerClassName,
	iconClassName,
}: AccordionProps) => {
	const header = (
		<Typography className={clsx(styles.Header, headerClassName)} variant="heading_4">
			{title}
		</Typography>
	);

	return (
		<Collapse
			defaultActiveKey={isOpen ? ['1'] : undefined}
			className={clsx(styles.Collapse, className)}
			expandIconPosition="end"
			bordered={false}
			expandIcon={({ isActive }) => (
				<Button.Open
					className={clsx(
						styles.Expand__Button,
						isActive && styles.Expand__Button_Active,
						iconClassName
					)}
				/>
			)}
		>
			<Panel key="1" className={styles.CollapsePanel} showArrow={!!content} header={header}>
				{content && <>{content}</>}
			</Panel>
		</Collapse>
	);
};
