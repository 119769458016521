import clsx from 'clsx';
import React from 'react';
import { Icons } from 'shared/ui/icons';

import { AllButtonProps, Button } from '../ui';
import styles from './styles.module.scss';

export type ArrowButtonProps = AllButtonProps & {
	arrowType?: 'angle' | 'up';
};

export const ArrowButton = ({ className, arrowType = 'angle', ...props }: ArrowButtonProps) => {
	const icon =
		arrowType === 'angle' ? <Icons.AngleArrow size={18} /> : <Icons.ArrowDown size={18} />;

	return (
		<Button
			className={clsx(styles.ArrowButton, styles[`ArrowButton_ArrowType_${arrowType}`], className)}
			appearance="icon"
			icon={icon}
			{...props}
		/>
	);
};
