import clsx from 'clsx';
import React from 'react';
import { Header } from 'shared/api';

import { MobileMenu } from './mobile-menu';
import { NavList } from './nav-list';
import styles from './styles.module.scss';

interface HeaderNavProps {
	menu: Header.MenuItem[];
	isMobileMenuOpen: boolean;
	onClose: () => void;
}

export const HeaderNav = ({ menu, isMobileMenuOpen, onClose }: HeaderNavProps) => {
	return (
		<nav className={clsx(styles.HeaderNav, isMobileMenuOpen && styles.HeaderNav_Open)}>
			{isMobileMenuOpen ? (
				<MobileMenu menu={menu} onClose={onClose} />
			) : (
				<NavList menu={menu} onClose={onClose} />
			)}
		</nav>
	);
};
