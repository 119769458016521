import React from 'react';
import { Page } from 'shared/api';
import { Button, Icons, ImageGroup } from 'shared/ui';

import { Paragraph } from '../paragraph';

export const PictureList = ({
	photo_bank_store_link,
	items,
}: Page.Block.Component.MediaLibraryPictureList) => {
	const images = items.map(({ caption, main_picture_src, preview_picture_src }) => ({
		caption,
		src: main_picture_src,
	}));

	return (
		<>
			<Paragraph block as="div">
				<ImageGroup items={images} />
			</Paragraph>
			<Button
				appearance="outlined"
				fullWidth
				target="__black"
				href={photo_bank_store_link.href}
				icon={<Icons.Image />}
			>
				{photo_bank_store_link.title}
			</Button>
		</>
	);
};
