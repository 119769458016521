import { createStore, Effect, Store } from 'effector';

export enum Status {
	Pending = 'Pending',
	Loading = 'Loading',
	Failed = 'Failed',
	Loaded = 'Loaded',
	Updating = 'Updating',
	FailedUpdating = 'FailedUpdating',
}

export const createStatus = <Params, Done, Fail = Error>(
	effect: Effect<Params, Done, Fail>,
	defaultValue = Status.Pending
): Store<Status> => {
	const $status = createStore<Status>(defaultValue);

	$status
		.on(effect, (state) => {
			switch (state) {
				case Status.Pending:
					return Status.Loading;
				case Status.Loaded:
					return Status.Updating;
				case Status.Failed:
					return Status.FailedUpdating;
				default:
					return;
			}
		})
		.on(effect.doneData, () => Status.Loaded)
		.on(effect.failData, () => Status.Failed);

	return $status;
};

export const isLoading = (status: Status) =>
	[Status.Loading, Status.Updating, Status.FailedUpdating].includes(status);
