import { Form, getServerError, ResetServerErrorFunc } from 'features/forms/form';
import React from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { Form as FormModel, Page } from 'shared/api';
import { Asserts } from 'yup';

import { schema } from './schema';

type FormFields = Asserts<typeof schema>;

type FormFieldsKey = keyof FormFields;

const { Input, Checkbox } = Form;

export const getFieldComponent = (
	{ name, label, placeholder }: Page.Block.Component.Form.FieldConfig,
	control: Control<FieldValues>,
	errors: FormModel.ValidationErrors | null,
	resetServerError: ResetServerErrorFunc
) => {
	const fieldName = name as FormFieldsKey;
	const commonProps = {
		control,
		name: fieldName,
		label,
		placeholder,
		serverError: getServerError(errors, name),
		resetServerError,
	};

	switch (name) {
		case 'email':
			return <Input {...commonProps} />;

		case 'full_name':
			return <Input {...commonProps} maxLength={200} />;

		case 'personal_agreements_confirm':
		case 'personal_newsletter_agreements_confirm':
			return <Checkbox {...commonProps} />;

		default:
			return null;
	}
};
