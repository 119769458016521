import { Input as AntdInput, InputProps as AntdInputProps, InputRef } from 'antd';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

import styles from './styles.module.scss';

export type InputProps = AntdInputProps;

export const Input = forwardRef<InputRef, InputProps>(({ className, ...props }, ref) => {
	return <AntdInput ref={ref} className={clsx(styles.Input, className)} {...props} />;
});

Input.displayName = 'Input';
