import type { UploadProps as AntdUploadProps } from 'antd';
import { Upload as AntdUpload } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload';
import React, { forwardRef, useState } from 'react';
import { notify } from 'widgets';

import { Button } from '../button';
import { Icons } from '../icons';
import { Typography } from '../typography';
import styles from './styles.module.scss';

const { Dragger } = AntdUpload;

export interface UploadProps extends Omit<AntdUploadProps, 'onChange'> {
	label?: string;
	sizeLimitMb?: number;
	allowedExtensions: string[];
	value?: UploadFile | null;
	onChange?: (value: UploadFile | null) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Upload = forwardRef<any, UploadProps>(
	({ label, allowedExtensions, maxCount = 1, sizeLimitMb, value, onChange, ...props }, ref) => {
		const [file, setFile] = useState<UploadFile | null>(null);

		const handleBeforeUpload = (file: RcFile) => {
			const isTrueType = allowedExtensions.includes(file.type);
			const sizeMb = file.size / 1024 / 1024;

			if (!isTrueType) {
				notify({ message: 'Неверный тип файла', type: 'error' });
			}

			if (sizeLimitMb && sizeMb > sizeLimitMb) {
				notify({
					message: `Превышен максимальный размер загружаемого файла: ${sizeLimitMb} мб.`,
					type: 'error',
				});
			}

			return isTrueType || AntdUpload.LIST_IGNORE;
		};

		const handleRemove = () => {
			setFile(null);
			onChange?.(null);
		};

		if (file) {
			return (
				<div className={styles.PreviewFile}>
					<Typography className={styles.PreviewFile__Name}>{file.name}</Typography>
					<Button icon={<Icons.Trash />} onClick={handleRemove} />
				</div>
			);
		}

		return (
			<Dragger
				className={styles.Upload}
				maxCount={maxCount}
				beforeUpload={handleBeforeUpload}
				customRequest={({ file, onSuccess }) => {
					setTimeout(() => {
						const loadedFile = file as UploadFile;
						setFile(loadedFile);
						onChange?.(loadedFile);
						onSuccess?.('ok');
					}, 0);
				}}
				{...props}
			>
				<Typography className={styles.Upload__Label} variant="button">
					{label}
					<span className={styles.Upload__Icon}>
						<Icons.Download />
					</span>
				</Typography>
			</Dragger>
		);
	}
);

Upload.displayName = 'Upload';
